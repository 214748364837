import React from "react";
import { FiHome } from "react-icons/fi";
import { GoPlus } from "react-icons/go";

export const PaymentMethods = ({ handleMethod }) => {
  const Card = ({ data }) => {
    return (
      <div className="cardPayment" onClick={() => handleMethod(true)}>
        {data.icon}
        {data.name}
      </div>
    );
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12 col-sm-12">
          <div className="paymentsMethodsBlock">
            <Card data={{ name: "Crédito", icon: <FiHome /> }} />
            <Card data={{ name: "Efectivo", icon: <FiHome /> }} />
            <div className="cardPayment cardPaymentNew" onClick={() => handleMethod(true)}>
              <GoPlus />
              Crear
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
