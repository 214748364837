import { ColorPicker } from "../../../../../../components/inputs/colorPicker";
import { Input } from "../../../../../../components/inputs/type2";

export const ShopInformation = () => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <div className="blockLogo">
            <div className="shopLogo"></div>
            <div>Actualizar logo</div>
          </div>
        </div>
        <div className="col-md-2 col-sm-12">
          <ColorPicker id={"primaryColorInput"} label="Color primario" description="" />
        </div>
        <div className="col-md-4 col-sm-12">
          <Input
            id={"shopNameInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Nombre de la tienda"
            description=""
          />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input
            id={"socialReasonInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Razón social"
            description=""
          />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input id={"CIFNIFInput"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="CIF/NIF" description="" />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-3 col-sm-12">
          <Input
            id={"cityInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Dirección fisica"
            description="Ciudad"
          />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input id={"streetInput"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Calle" />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input id={"numberInput"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Número" />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input
            id={"cpInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label=""
            description="Código postal"
          />
        </div>
        <div className="col-md-4 col-sm-12">
          <Input id={"flatInput"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Piso" />
        </div>
        <div className="col-md-4 col-sm-12">
          <Input id={"doorInput"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Puerta" />
        </div>
        <div className="col-md-4 col-sm-12">
          <Input
            id={"flatBlockInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label=""
            description="Bloque"
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6 col-sm-12">
          <Input
            id={"mailInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Correo electrónico"
            description=""
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Input
            id={"phoneInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Teléfono de contacto"
            description=""
          />
        </div>
      </div>

      <div className="row mb-1">
        <div className="col-md-12 col-sm-12">
          <hr />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-12 col-sm-12">
          <div className="inputLabel">Dirección fiscal</div>
          <input type="checkbox" onChange={() => null} checked={false} className="mt-3" /> Utilizar la misma dirección física
        </div>
        <div className="col-md-3 col-sm-12">
          <Input id={"cityInput2"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Ciudad" />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input id={"streetInput2"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Calle" />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input id={"numberInput2"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Número" />
        </div>
        <div className="col-md-3 col-sm-12">
          <Input
            id={"cpInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label=""
            description="Código postal"
          />
        </div>
        <div className="col-md-4 col-sm-12">
          <Input id={"flatInput2"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Piso" />
        </div>
        <div className="col-md-4 col-sm-12">
          <Input id={"doorInput2"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="" description="Puerta" />
        </div>
        <div className="col-md-4 col-sm-12">
          <Input
            id={"flatBlockInput2"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label=""
            description="Bloque"
          />
        </div>
      </div>
    </>
  );
};
