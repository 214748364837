import { useState, useEffect } from "react";
//Redux
//Components
import { FiHome } from "react-icons/fi";
import { Button } from "./../../../../components/button";
import { GeneralInformation } from "./pages/generalInformation";
import { UsersAndPermissions } from "./pages/usersAndPermissions";
import { PaymentsMethods } from "./pages/paymentsMethods";
import { DeliveryMethods } from "./pages/deliveryMethods";
import { Domains } from "./pages/domains";
import { Policies } from "./pages/policies";
//Functions
//Styles
import "./style.css";
import { DevelopTime } from "./pages/developTime";
import { Printers } from "./pages/printers";
import { AdvancedConfiguration } from "./pages/advancedConfiguration";

export const ConfigurationPanel = ({ title, content, hasButtonSave = true, action = () => null }) => {
  return (
    <div className="adminPanel mb-2 h-auto">
      <div className="adminPanelContent">
        <div className="configurationPanelContentTitle">
          {title}
          {hasButtonSave && <Button key={"Save"} text={"Guardar"} icon={<FiHome />} action={action} customClass={"customButtonColor2"} />}
        </div>
        {content}
      </div>
    </div>
  );
};

export const Configuration = () => {
  const [selectedButton, setSelectedButton] = useState(100);
  const [content, setContent] = useState();

  const handleMenu = (option) => {
    setSelectedButton(option.id);
    setContent(option.content);
  };

  const blockMenu = [
    {
      id: 100,
      name: "Información general",
      icon: <FiHome />,
      content: <GeneralInformation />,
    },
    {
      id: 101,
      name: "Usuarios y permisos",
      icon: <FiHome />,
      content: <UsersAndPermissions />,
    },
    {
      id: 102,
      name: "Métodos de pago",
      icon: <FiHome />,
      content: <PaymentsMethods />,
    },
    {
      id: 103,
      name: "Métodos de entrega y envío",
      icon: <FiHome />,
      content: <DeliveryMethods />,
    },
    {
      id: 104,
      name: "Dominios",
      icon: <FiHome />,
      content: <Domains />,
    },
    {
      id: 105,
      name: "Políticas",
      icon: <FiHome />,
      content: <Policies />,
    },
    {
      id: 106,
      name: "Tiempos de preparación",
      icon: <FiHome />,
      content: <DevelopTime />,
    },
    {
      id: 107,
      name: "Impresoras",
      icon: <FiHome />,
      content: <Printers />,
    },
    {
      id: 108,
      name: "Configuración avanzada",
      icon: <FiHome />,
      content: <AdvancedConfiguration />,
    },
  ];

  const MenuDesign = ({ block }) => {
    return block.map((option) => {
      const activeStyle = option.id === selectedButton ? " optionMenuActive " : "";
      return (
        <div onClick={() => handleMenu(option)} className={"configurationMenu " + activeStyle}>
          <div className="configurationMenuIcon">{option.icon}</div>
          <div style={{ paddingTop: "4px" }}>{option.name}</div>
        </div>
      );
    });
  };

  useEffect(() => {
    handleMenu(blockMenu[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="topContainer">
        <div className="bodyTitle">Configuración</div>
        <div className="actionsDashboard"></div>
      </div>

      <div className="row w-100">
        <div style={{ width: "250px" }}>
          <div className="row mb-3">
            <div className="col-12" style={{ padding: "0" }}>
              <div className="adminPanel">
                <div className="adminPanelContent">
                  <div className="adminPanelContentTitle">Mi tienda</div>
                  <MenuDesign block={blockMenu} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row mb-3">
            <div className="col-12">{content}</div>
          </div>
        </div>
      </div>
    </>
  );
};
