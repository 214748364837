export const CustomBlock = ({ name, description = "", content }) => {
  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="adminPanel">
          <div className="adminPanelContent">
            <div className="inputLabel">{name}</div>
            {description !== "" && <div className="inputDescription">{description}</div>}
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};
