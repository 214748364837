import { Input } from "../../../../../../components/inputs/type2";

export const OwnerInformation = () => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6 col-sm-12">
          <Input
            id={"ownerNameInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Nombre del propietario"
            description=""
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Input id={"DNIInput"} type={"text"} placeholder="" icon={null} classname={""} iconPositionRight={true} label="DNI" description="" />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-6 col-sm-12">
          <Input
            id={"ownermailInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Correo electrónico"
            description=""
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Input
            id={"ownerPhoneInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Teléfono de contacto"
            description=""
          />
        </div>
      </div>
    </>
  );
};
