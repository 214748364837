import { useEffect } from "react";
//Redux
import { useDispatch } from "react-redux";
import { updateDatatableCheckColumn, updateFloatPanelVisibility, setFloatPanelComponent } from "../../../../reducers/userConfigSlice";
//Components
import { dataProductos } from "../../../../components/datatable/mock";
import Switch from "@mui/material/Switch";
import { Button } from "../../../../components/button";
import { TbPackage } from "react-icons/tb";
import { PiExport } from "react-icons/pi";
import { FloatComponent } from "./floatComponent";
//Styles
import "./style.css";
import { Datatable } from "../../../../components/datatable";

export const Products = () => {
  const dispatch = useDispatch();

  const testCallback = () => {
    //TODO request new data for table
    return null;
  };

  // =========== Datatable Configuration ============
  const sections = [
    { text: "Todos", callback: testCallback, actions: ["search", "filter", "order", "select"], actionsWithSelect: ["clone", "delete", "cancel"] },
    {
      text: "En borrador",
      callback: testCallback,
      actions: ["search", "filter", "order", "select"],
      actionsWithSelect: ["clone", "delete", "cancel"],
    },
    { text: "Recomendaciones", callback: testCallback, actions: ["filter", "order"], actionsWithSelect: [] },
  ];
  const customHeaders = {
    id: "Referencia",
    image: "Imagen",
    name: "Nombre",
    category: "Categorías",
    catalog: "Catálogos",
    price: "Precio",
    state: "Activar/Desactivar",
  };
  const customData = {
    id: (data) => <b>{data}</b>,
    image: (data) => <div className="background-image datatableImg" style={{ backgroundImage: "url(" + data + ")" }}></div>,
    price: (data) => data + "€",
    state: (data) => (data ? <Switch defaultChecked /> : <Switch />),
  };
  // ================================================

  const handleFloatPanelVisibility = (data = null) => {
    dispatch(updateFloatPanelVisibility());
    dispatch(setFloatPanelComponent(<FloatComponent data={data} />));
  };

  useEffect(() => {
    dispatch(updateDatatableCheckColumn(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="topContainer">
        <div className="bodyTitle">Productos</div>
        <div className="actionsDashboard">
          <Button text={"Exportar"} icon={<PiExport />} />
          <Button text={"Agregar producto"} icon={<TbPackage />} customClass={"customButtonColor2"} action={handleFloatPanelVisibility} />
        </div>
      </div>
      <div className="datatableContainer">
        {
          <Datatable
            data={dataProductos}
            customHeaders={customHeaders}
            customData={customData}
            sections={sections}
            rowCallback={handleFloatPanelVisibility}
          />
        }
      </div>
    </>
  );
};
