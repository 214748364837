import React from "react";
import { FiHome } from "react-icons/fi";

export const Delivery = ({ handleMethod, activePanels }) => {
  const Card = ({ data }) => {
    return (
      <div className={activePanels.includes(data.id) ? "cardPayment" : "cardPayment cardPaymentNew"} onClick={() => handleMethod(data.id)}>
        {data.icon}
        {data.name}
      </div>
    );
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12 col-sm-12">
          <div className="paymentsMethodsBlock">
            <Card data={{ name: "Domicilio", icon: <FiHome />, id: 0 }} />
            <Card data={{ name: "Tienda", icon: <FiHome />, id: 1 }} />
          </div>
        </div>
      </div>
    </>
  );
};
