import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
import { setUserLogOut } from "./../../../reducers/userConfigSlice";
//Components
import { Input } from "../../../components/inputs/type1";
import { TbSearch } from "react-icons/tb";
import { VscBell } from "react-icons/vsc";
import { FaCircle } from "react-icons/fa";
import Logo from "./../../../assets/logo/logo-delibran.svg";
//Styles
import "./style.css";
import { Select } from "../../../components/select";
//Api
import { logOut } from "../../../api/login";

export const AdminBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="adminBar desktop">
      <div className="toolsDashboard">
        <div className="searcher">
          <Input
            id={"searchAdminInput"}
            type={"text"}
            placeholder="Buscar"
            disabled={""}
            onKeyPress={() => null}
            icon={<TbSearch />}
            classname={"searchAdminInput no-border"}
            iconPositionRight={false}
          />
        </div>
        <div className="alerts">
          <VscBell />
          {true ? <div className="dot"></div> : <></>}
        </div>
      </div>
      <div>
        <img src={Logo} alt="logo" className="brandDashboard" />
      </div>
    </div>
  );
};
