import { Button } from "../../components/button";
import "./style.css";

export const HorizontalNavigation = ({ options, callback, tabActive }) => {
  return (
    <div className="horizontalNavigation">
      <div className="horizontalNavigationContainer">
        {options.map((button) => (
          <Button
            text={button.text}
            icon={button.icon}
            customClass={tabActive === button.tab ? "customButtonColor2" : "customButtonColor1"}
            action={() => callback(button.tab)}
          />
        ))}
      </div>
    </div>
  );
};
