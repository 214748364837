import { useState, useEffect } from "react";
//Redux
import { useDispatch } from "react-redux";
import { updateDatatableCheckColumn, updateFloatPanelVisibility, setFloatPanelComponent } from "../../../../reducers/userConfigSlice";
//Components
import { dataDiscounts } from "../../../../components/datatable/mock";
import { GoPlus } from "react-icons/go";
import { PiExport } from "react-icons/pi";
import { Button } from "../../../../components/button";
import {FloatComponent} from "./floatComponent";
//Functions
//Styles
import "./style.css";
import { Datatable } from "../../../../components/datatable";

export const Discounts = () => {
  const dispatch = useDispatch();

  const testCallback = () =>{
    //TODO request new data for table
    return null;
  }

  // =========== Datatable Configuration ============
  const sections = [{ text: null, callback: testCallback, actions:["search", "filter", "select"], actionsWithSelect:["clone", "delete", "cancel"] },];
  const customHeaders = {
    discount: "Nombre",
    active: "Activo",
    type: "Tipo",
    used: "Usado",
  };
  const customData = {
    discount: (data) => (
      <div className="text-align-left">
        <div>
          <b>{data.name}</b>
        </div>
        <div>
          <small>{data.description}</small>
        </div>
      </div>
    ),
    active: (data) =>
      data ? (
        <Button text={"Activo"} customClass={"customButtonGreen"} action={() => null} />
      ) : (
        <Button text={"Vencido"} customClass={"customButtonDisabled"} action={() => null} />
      ),
  };
  // ================================================


  const handleFloatPanelVisibility = (data=null) => {
    dispatch(updateFloatPanelVisibility());
    dispatch(setFloatPanelComponent(<FloatComponent data={data}/>));
  };

  useEffect(() => {
    dispatch(updateDatatableCheckColumn(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="topContainer">
        <div className="bodyTitle">Descuentos</div>
        <div className="actionsDashboard">
          <Button text={"Exportar"} icon={<PiExport />} />
          <Button text={"Crear descuento"} icon={<GoPlus />} customClass={"customButtonColor2"} action={handleFloatPanelVisibility} />
        </div>
      </div>
      <div className="datatableContainer">
        {<Datatable data={dataDiscounts} customHeaders={customHeaders} customData={customData}  sections={sections} />}
      </div>
    </>
  );
};
