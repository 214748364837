import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

//https://www.npmjs.com/package/react-drag-drop-files
export const DragDropFiles = ({ className }) => {
  const fileTypes = ["JPG", "PNG", "GIF"];

  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <FileUploader label="Seleccione o suelte un archivo aquí mismo" classes={className} handleChange={handleChange} name="file" types={fileTypes} />
  );
};
