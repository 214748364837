import React from "react";
import { ConfigurationPanel } from "../..";
import { Input } from "../../../../../../components/inputs/type2";
import { Button } from "../../../../../../components/button";
import { Switch } from "@mui/material";

export const Domains = () => {
  const Settings = () => {
    const settingsList = [
      {
        id: 0,
        name: "Tienda online",
        description: "shop.[subdomain].delibran.com",
        action1: <Switch defaultChecked disabled={true} />,
        action2: <></>,
      },
      {
        id: 1,
        name: "Punto de venta",
        description: "pos.[subdomain].delibran.com",
        action1: <Switch defaultChecked disabled={true} />,
        action2: <Button text={"Asignar contraseña"} icon={null} />,
      },
      {
        id: 2,
        name: "Gestor de pedidos",
        description: "order.[subdomain].delibran.com",
        action1: <Switch disabled={true} />,
        action2: <></>,
      },
      {
        id: 3,
        name: "Repartidor",
        description: "delivery.[subdomain].delibran.com",
        action1: <Switch disabled={true} />,
        action2: <></>,
      },
    ];

    return (
      <div className="settingAttributes">
        {settingsList.map((setting) => (
          <div className="settingRow">
            <div className="settingInfo">
              <div className="settingName">{setting.name}</div>
              <div className="settingDescription">{setting.description}</div>
            </div>
            <div className="settingAction1">{setting.action2}</div>
            <div className="settingAction2">{setting.action1}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <ConfigurationPanel title="Dominios" content={<Settings />} />
    </>
  );
};
