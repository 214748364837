import React, { useState } from "react";
import { ConfigurationPanel } from "../..";
import { PaymentMethods } from "./paymentMethods";
import { Input } from "../../../../../../components/inputs/type2";
import { Textarea } from "../../../../../../components/inputs/textarea";

export const PaymentsMethods = () => {
  const [editionPayment, showEditionPayment] = useState(false);

  const handleMethod = (newValue) => {
    showEditionPayment(newValue);
  };

  const EditionPanel = () => {
    return (
      <div className="row">
        <div className="col-12 mb-2">
          <Input
            id={"nameInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={""}
            iconPositionRight={true}
            label="Nombre de la forma de pago"
            description=""
          />
        </div>
        <div className="col-12 mb-2">
          <Textarea id={"extraDetailsInput"} placeholder={""} classname={""} label={"Detalles adiccionales"} description={""} />
        </div>
        <div className="col-12 mb-2">
          <Textarea id={"paymentguideInput"} placeholder={""} classname={""} label={"Instrucciones de pago"} description={""} />
        </div>
      </div>
    );
  };

  return (
    <>
      <ConfigurationPanel title="Métodos de pago" content={<PaymentMethods handleMethod={handleMethod} />} />
      {editionPayment && <ConfigurationPanel title="Crédito" content={<EditionPanel />} action={() => handleMethod(false)} />}
    </>
  );
};
