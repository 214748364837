import { useState } from "react";
//Redux
import { useDispatch } from "react-redux";
import { setAlert } from "./../../../../reducers/userConfigSlice";
//Components
import { Select } from "../../../../components/select";
import { HiOutlineSearch } from "react-icons/hi";
import { FloatPanelTemplate } from "../../../../components/floatPanel/floatPanelTemplate";
import { Input } from "../../../../components/inputs/type2";
import { Textarea } from "../../../../components/textarea/type1";
import { Button } from "../../../../components/button";
import { dataCategories } from "../../../../components/datatable/mock";
import { Datatable } from "../../../../components/datatable";
import { BiMinus, BiPlus } from "react-icons/bi";
import Popup from "../../../../components/popup";
import { DraggableList } from "../../../../components/draggableList";
import { CustomBlock } from "../../../../components/customBlock";

export const FloatComponent = ({ data }) => {
  const dispatch = useDispatch();

  const ContainerPopupTable = ({ name = "", description = "", popupOpen, togglePopup, data, customHeaders, sections }) => {
    return (
      <>
        <div>
          {name !== "" && <div className="inputLabel">{name} </div>}
          {description !== "" && <div className="inputDescription">{description}</div>}
        </div>
        <div className="product-organitation-area">
          <Button text={"Carta Online"} icon={<BiMinus />} />
          <Button text={"Agregar catálogo"} icon={<BiPlus />} customClass={"customButtonColor2"} action={togglePopup} />
          <Popup
            isOpen={popupOpen}
            togglePopup={togglePopup}
            content={<Datatable data={dataCategories} customHeaders={customHeaders} customData={{}} sections={sections} />}
          />
        </div>
      </>
    );
  };

  const SimpleTableDraggable = ({ name, description }) => {
    return (
      <>
        <div>
          <div className="inputLabel">{name} </div>
          <div className="inputDescription">{description}</div>
        </div>
        <div>
          <DraggableList />
        </div>
      </>
    );
  };

  const ProductOrganitation = () => {
    const [isOpenCatalogs, setIsOpenCatalogs] = useState(false);
    const [isOpenCategories, setIsOpenCategories] = useState(false);
    const togglePopupCatalogs = () => {
      setIsOpenCatalogs(!isOpenCatalogs);
    };
    const togglePopupCategories = () => {
      setIsOpenCategories(!isOpenCategories);
    };
    const customHeaders = {
      name: "Nombre",
      description: "Imagen",
      productQuantity: "Descripción",
      state: "Nº de productos",
    };
    const sections = [{ text: null, callback: () => null, actions: ["search", "select"], actionsWithSelect: ["cancel"] }];
    return (
      <>
        <div className="product-organitation">
          <div className="row">
            <div className="col-md-6">
              <ContainerPopupTable
                name={"Catálogos"}
                description={"Seleccione los catálogos en los que estará"}
                popupOpen={isOpenCatalogs}
                togglePopup={togglePopupCatalogs}
                data={dataCategories}
                customHeaders={customHeaders}
                sections={sections}
              />
            </div>
            <div className="col-md-6">
              <ContainerPopupTable
                name={"Categorías"}
                description={"Selecciona las categorías en las que estará"}
                popupOpen={isOpenCategories}
                togglePopup={togglePopupCategories}
                data={dataCategories}
                customHeaders={customHeaders}
                sections={sections}
              />
            </div>
          </div>
        </div>

        <div className="product-channels"></div>
      </>
    );
  };

  const ProductSeo = () => {
    const [name, updateName] = useState("Nombre del artículo");
    const [url, updateUrl] = useState("https://delibran.com/");
    const [description, updateDescription] = useState(
      "Esta es una breve descripción del contenido de la página. Es una vista previa del texto que puedes encontrar si visitas la página."
    );

    return (
      <>
        <div className="row mb-3">
          <div className="col-md-12 col-sm-12">
            <Input
              id={"seoNameInput"}
              type={"text"}
              placeholder=""
              icon={null}
              classname={""}
              iconPositionRight={true}
              label="Título de la página"
              description=""
              onChange={(e) => updateName(e.target.value)}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12 col-sm-12">
            <Input
              id={"seoUrlInput"}
              type={"text"}
              placeholder=""
              icon={null}
              classname={""}
              iconPositionRight={true}
              label="Identificador de URL"
              description="Ej: manzana-roja"
              onChange={(e) => updateUrl(e.target.value)}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12 col-sm-12">
            <Textarea
              id={"seoDescriptionInput"}
              onChange={(e) => updateDescription(e.target.value)}
              text={"Meta descripción"}
              label="Meta descripción del producto"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12 col-sm-12">
            <div>
              <b>Previsualización del motor de búsqueda</b>
            </div>
            <div class="search-result">
              <div class="result-title">{name}</div>
              <div class="result-url">{url}</div>
              <div class="result-description">{description}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ProductAlergens = () => {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      console.log("togglePopup");
      setIsOpen(!isOpen);
      dispatch(
        setAlert({
          called: true,
          title: "Publicado correctamente",
          message: "Los alérgenos han sido modificados con éxito.",
          type: "success",
        })
      );
    };

    const customHeaders = {
      name: "Nombre",
      description: "Icono",
    };
    const sections = [{ text: null, callback: () => null, actions: ["search", "select"], actionsWithSelect: ["cancel"] }];
    return (
      <>
        <div className="product-organitation">
          <div className="row">
            <div className="col-md-12">
              <ContainerPopupTable
                popupOpen={isOpen}
                togglePopup={togglePopup}
                data={dataCategories}
                customHeaders={customHeaders}
                sections={sections}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ProductAttributes = () => {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };

    const customHeaders = {
      name: "Nombre",
      description: "Icono",
    };
    const sections = [{ text: null, callback: () => null, actions: ["search", "select"], actionsWithSelect: ["cancel"] }];
    return (
      <>
        <div className="product-organitation">
          <div className="row">
            <div className="col-md-12">
              <ContainerPopupTable
                popupOpen={isOpen}
                togglePopup={togglePopup}
                data={dataCategories}
                customHeaders={customHeaders}
                sections={sections}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const content = {
    title: "Producto Nuevo",
    isActivated: { enabled: true, callback: () => console.log("action") },
    rightActions: [
      { text: "Guardar como borrador", icon: <HiOutlineSearch />, customClass: "customButtonColor1", callback: () => console.log("action") },
      { text: "Publicar", icon: <HiOutlineSearch />, customClass: "customButtonColor2", callback: () => null },
    ],
    horizontalNavigation: [
      { tab: 0, text: "Detalles principales", icon: null },
      { tab: 1, text: "Atributos / Variantes", icon: null },
    ],
    content: [
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <CustomBlock
                  name={"Detalles"}
                  content={
                    <>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="row mb-3">
                            <div className="col-md-6 col-sm-12">
                              <Input
                                id={"nameInput"}
                                type={"text"}
                                placeholder="Nombre"
                                icon={null}
                                classname={""}
                                iconPositionRight={true}
                                label="Nombre"
                                description="Nombre completo"
                              />
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <Input
                                id={"nameTPVInput"}
                                type={"text"}
                                placeholder="Nombre TPV"
                                icon={null}
                                classname={""}
                                iconPositionRight={true}
                                label="Nompre TPV"
                                description="Nombre que se mostrará en el TPV"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6 col-sm-12">
                              <Input
                                id={"referenceInput"}
                                type={"text"}
                                placeholder="Referencia"
                                icon={null}
                                classname={""}
                                iconPositionRight={true}
                                label="Referencia"
                                description="Referencia para el producto"
                              />
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <Input
                                id={"barcodeInput"}
                                type={"text"}
                                placeholder="Código de barras"
                                icon={null}
                                classname={""}
                                iconPositionRight={true}
                                label="Código de barras"
                                description="Escriba el código de barras"
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-12 col-sm-12">
                              <Textarea id={"descriptionProduct"} text={"Descripción"} label="Descripción del producto" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="adminPanelContentTitle">Imágenes</div>
                          <div className="product-container">
                            <div className="product-image">Imagen Destacada</div>
                            <div className="product-image-square">
                              <div className="product-image-alt">Img ALT</div>
                              <div className="product-image-alt">Img ALT</div>
                              <div className="product-image-alt">Img ALT</div>
                              <div className="product-image-alt">Img ALT</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                />

                <CustomBlock
                  name={"Precio"}
                  content={
                    <>
                      <div className="row mb-3">
                        <div className="col-md-6 col-sm-12">
                          <Select
                            values={[
                              { id: 0, name: "Unidad" },
                              { id: 1, name: "Kg" },
                              { id: 2, name: "L" },
                            ]}
                            callback={() => null}
                            label="Unidades"
                            description="Unidades para medir el artículo"
                            classname={""}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <Select
                            values={[
                              { id: 0, name: "21%" },
                              { id: 1, name: "7%" },
                              { id: 2, name: "4%" },
                            ]}
                            callback={() => null}
                            label="IVA"
                            description="Iva a aplicar"
                            classname={""}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4 col-sm-12">
                          <Input
                            id={"pvimpexclInput"}
                            type={"text"}
                            placeholder=""
                            icon={null}
                            classname={""}
                            iconPositionRight={true}
                            label="Precio de venta (imp. excl.)"
                            description="Precio con imp. excluido"
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <Input
                            id={"pvimpinclInput"}
                            type={"text"}
                            placeholder=""
                            icon={null}
                            classname={""}
                            iconPositionRight={true}
                            label="Precio de venta (imp. incl.)"
                            description="Precio con imp. incluido"
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <Input
                            id={"pvcomparative"}
                            type={"text"}
                            placeholder=""
                            icon={null}
                            classname={""}
                            iconPositionRight={true}
                            label="Precio de comparación"
                            description="Precio de comparación con el anterior"
                          />
                        </div>
                      </div>
                    </>
                  }
                />

                <CustomBlock name={"Organización del producto"} content={<ProductOrganitation />} />

                <CustomBlock name={"SEO"} content={<ProductSeo />} />
              </div>
            </div>
          </>
        ),
      },
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <CustomBlock
                  name={"Alérgenos"}
                  description={"Agregue o elimine alérgenos"}
                  content={
                    <>
                      <ProductAlergens />
                    </>
                  }
                />
                <CustomBlock
                  name={"Opciones"}
                  description={"Agregue o elimine opciones"}
                  content={
                    <>
                      <SimpleTableDraggable />
                    </>
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <CustomBlock
                  name={"Atributos"}
                  description={"Agregue o elimine atributos"}
                  content={
                    <>
                      <ProductAttributes />
                    </>
                  }
                />
                <CustomBlock
                  name={"Variantes"}
                  description={"Agregue o elimine variantes"}
                  content={
                    <>
                      <SimpleTableDraggable />
                    </>
                  }
                />
              </div>
            </div>
          </>
        ),
      },
    ],
  };
  return <FloatPanelTemplate data={content} />;
};
