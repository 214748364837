import React, { useState } from "react";
import { CiClock2 } from "react-icons/ci";
import "./style.css";

export const TimePicker = ({ label, description, callback }) => {
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");

  const handleHourChange = (e) => {
    const newHour = e.target.value.padStart(2, "0");
    setHour(newHour);
    if (callback) {
      callback(`${newHour}:${minute}`);
    }
  };

  const handleMinuteChange = (e) => {
    const newMinute = e.target.value.padStart(2, "0");
    setMinute(newMinute);
    if (callback) {
      callback(`${hour}:${newMinute}`);
    }
  };

  return (
    <>
      <div className="inputLabel">{label}</div>
      <div className="inputDescription">{description}</div>
      <div className="timePicker">
        <CiClock2 />
        <select value={hour} onChange={handleHourChange}>
          {[...Array(24).keys()].map((h) => (
            <option key={h} value={h.toString().padStart(2, "0")}>
              {h.toString().padStart(2, "0")}
            </option>
          ))}
        </select>
        :
        <select value={minute} onChange={handleMinuteChange}>
          {[...Array(60).keys()].map((m) => (
            <option key={m} value={m.toString().padStart(2, "0")}>
              {m.toString().padStart(2, "0")}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default TimePicker;
