import { useState, useEffect } from "react";
//Redux
import { useDispatch } from "react-redux";
import { updateDatatableCheckColumn, updateFloatPanelVisibility, setFloatPanelComponent } from "../../../../reducers/userConfigSlice";
//Components
import { dataCategories } from "../../../../components/datatable/mock";
import Switch from "@mui/material/Switch";
import { Input } from "../../../../components/inputs/type1";
import { FloatRightPanel } from "../../../../components/floatRightPanel";
import { DragDropFiles } from "../../../../components/dragDropFiles";
import { Select } from "../../../../components/select";
import { TbSearch } from "react-icons/tb";
import { GoPlus } from "react-icons/go";
import {FloatComponent} from "./floatComponent";
import { PiCurrencyEur, PiEgg, PiFish, PiExport } from "react-icons/pi";
import { Button } from "../../../../components/button";
//Functions
import { stateTags } from "../../../../functions";
//Styles
import "./style.css";
import { Datatable } from "../../../../components/datatable";

export const Catalogs = () => {
  const dispatch = useDispatch();

  const testCallback = () =>{
    //TODO request new data for table
    return null;
  }

  // =========== Datatable Configuration ============
  const sections = [{ text: null, callback: testCallback, actions:["search", "select"], actionsWithSelect:["clone", "delete", "cancel"] },];
  const customHeaders = {
    name: "Nombre",
    description: "Descripción",
    productQuantity: "Nº productos",
    state: "Activar/Desactivar",
  };
  const customData = {
    state: (data) => (data ? <Switch defaultChecked /> : <Switch />),
  };
  // ================================================

  const handleFloatPanelVisibility = (data=null) => {
    dispatch(updateFloatPanelVisibility());
    dispatch(setFloatPanelComponent(<FloatComponent data={data}/>));
  };

  useEffect(() => {
    dispatch(updateDatatableCheckColumn(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="topContainer">
        <div className="bodyTitle">Catálogos</div>
        <div className="actionsDashboard">
          <Button text={"Exportar"} icon={<PiExport />} />
          <Button text={"Agregar catálogo"} icon={<GoPlus />} customClass={"customButtonColor2"} action={handleFloatPanelVisibility} />
        </div>
      </div>
      <div className="datatableContainer">
        {<Datatable data={dataCategories} customHeaders={customHeaders} customData={customData} sections={sections} />}
      </div>
    </>
  );
};
