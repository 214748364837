import { Select } from "../../../../components/select";
import { HiOutlineSearch } from "react-icons/hi";
import { FloatPanelTemplate } from "../../../../components/floatPanel/floatPanelTemplate";
import { CustomBlock } from "../../../../components/customBlock";

export const FloatComponent = ({ data }) => {
  const mockOrderDetails = {
    state: "En preparación",
    order: "31542-F1",
    payment: "Pagado",
    preparationTime: "00:12:26",
    orderDate: "05 de Julio de 2024",
    details: [
      { id: 0, name: "Manzana roja", ref: "5363", price: 5, quantity: 5, discount: -10.99 },
      { id: 0, name: "Manzana verde", ref: "5362", price: 5, quantity: 15, discount: -5.99 },
    ],
  };

  const mockTimeline = [
    { id: 0, name: "Productos añadidos al carrito", date: "12:24 de Jun, 2024" },
    { id: 1, name: "Carrito abandonado", date: "12:24 de Jun, 2024" },
    { id: 2, name: "Pedido reactivado", date: "12:24 de Jun, 2024" },
    { id: 3, name: "Pago realizado", date: "12:24 de Jun, 2024" },
  ];

  const OrderItem = ({ data }) => {
    return (
      <div className="order-item">
        <div className="order-item-info">
          <div className="order-item-icon"></div>
          <div className="order-item-name">
            <div>
              <b>{data.name}</b>
            </div>
            <div>REFERENCIA: {data.ref}</div>
          </div>
        </div>
        <div className="order-item-totals">
          <div className="order-item-totals-row">
            <div className="order-item-totals-row-text">x{data.quantity}</div>{" "}
            <div className="order-item-totals-row-value">{data.quantity * data.price} €</div>
          </div>
          <div className="order-item-totals-row">
            <div className="order-item-totals-row-text">Descuento</div> <div className="order-item-totals-row-value">{data.discount} €</div>
          </div>
          <div className="order-item-totals-row">
            <div className="order-item-totals-row-text">Total</div>{" "}
            <div className="order-item-totals-row-value">{data.quantity * data.price - data.discount} €</div>
          </div>
        </div>
      </div>
    );
  };

  const InvoiceResume = () => {
    return (
      <div>
        <div className="invoiceResume-Header">
          <div>
            <div className="invoiceResume-Header-title">No Factura</div>
            <div className="invoiceResume-Header-value">31542-F1</div>
          </div>
          <div>
            <div className="invoiceResume-Header-title text-align-right">Canal de venta</div>
            <div className="invoiceResume-Header-value text-align-right">Tienda online</div>
          </div>
        </div>
        <div className="invoiceResume-Body">
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Subtotal</div>
            <div className="invoiceResume-note">2 artículos</div>
            <div className="invoiceResume-value">49,98€</div>
          </div>
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Descuento</div>
            <div className="invoiceResume-note">X9FJBZ8P</div>
            <div className="invoiceResume-value">-10,99€</div>
          </div>
          <div className="invoiceResume-Separator"></div>
          <div className="invoiceResume-Item">
            <div className="invoiceResume-title">Total</div>
            <div></div>
            <div className="invoiceResume-value">38,99€</div>
          </div>
        </div>
      </div>
    );
  };

  const content = {
    title: "Detalles del pedido",
    isActivated: { enabled: false, callback: () => console.log("action") },
    rightActions: [{ text: "Guardar", icon: <HiOutlineSearch />, customClass: "customButtonColor1", callback: () => console.log("action") }],
    horizontalNavigation: [],
    content: [
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <CustomBlock
                  name={""}
                  content={
                    <div className="order-container">
                      <div className="order-header">
                        <div className="order-information">
                          <div className="order-state">{mockOrderDetails.state}</div>
                          <div className="order-number">
                            <div className="order-number-text">No. pedido</div>
                            <div className="order-number-value">{mockOrderDetails.order}</div>
                          </div>
                        </div>
                        <div className="order-preparationTime">
                          <div className="order-number-text">Tiempo de preparación</div>
                          <div className="order-number-value">{mockOrderDetails.preparationTime}</div>
                        </div>
                      </div>

                      <div className="order-date">
                        <div className="order-number-text">Fecha de pedido</div>
                        <div className="order-number-value">{mockOrderDetails.orderDate}</div>
                      </div>

                      <div className="order-details">
                        {mockOrderDetails.details.map((detail) => (
                          <OrderItem data={detail} />
                        ))}
                      </div>
                    </div>
                  }
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <CustomBlock name={""} content={<InvoiceResume />} />
                <CustomBlock name={"Notas"} content={<div>Sin notas del pedido</div>} />
                <CustomBlock
                  name={"Cliente"}
                  content={
                    <>
                      <div>Miguel Ángel</div>
                      <div>10 pedidos</div>
                    </>
                  }
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <CustomBlock
                  name={"Timeline"}
                  content={
                    <>
                      <div className="timeline-container">
                        <ul className="timeline">
                          {mockTimeline.map((timeline) => (
                            <li>
                              <div className="circleRounded">
                                <div className="circle"></div>
                              </div>
                              <div className="event">
                                <div className="event-name">{timeline.name}</div>
                                <div className="event-date">{timeline.date}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  }
                />
              </div>
            </div>
          </>
        ),
      },
    ],
  };
  return <FloatPanelTemplate data={content} />;
};
