import React from "react";

import { BiCheck } from "react-icons/bi";
import { Button } from "../button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./style.css";

export const Popup = ({ isOpen, content, togglePopup }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "0px",
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };
  return (
    <Modal open={isOpen} onClose={() => null} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        {content}
        {<Button text={"Listo"} icon={<BiCheck />} customClass={"customButtonColor2"} action={togglePopup} />}
      </Box>
    </Modal>
  );
};

export default Popup;
