import "./style.css";
export const Textarea = ({ id, text, label, onChange = () => null }) => {
  return (
    <>
      <div className="inputLabel">{text}</div>
      <div className="inputDescription">{label}</div>
      <textarea id={id} className="textarea" onChange={onChange} />
    </>
  );
};
