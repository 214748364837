//Components

import { FaCircle } from "react-icons/fa";
import { VscBell } from "react-icons/vsc";
//Styles
import Logo from "./../../../../assets/logo/logo-delibran.svg";
import "./style.css";

export const AdminBarMobile = () => {
  return (
    <>
      <div className="mobile nowrap adminBarMobile">
        <div className="userIconMobile">
          <FaCircle />
        </div>
        <div className="logoMobile">
          <img src={Logo} alt="logo" />
        </div>
        <div className="alertsMobile">
          <VscBell />
        </div>
      </div>
    </>
  );
};
