import { useState } from "react";
//Redux
import { useDispatch } from "react-redux";
import { updateFloatPanelVisibility, setFloatPanelComponent } from "./../../reducers/userConfigSlice";
//Components
import Switch from "@mui/material/Switch";
import { Button } from "../../components/button";
import { IoMdClose } from "react-icons/io";
import { HorizontalNavigation } from "../horizontalNavigation";

export const FloatPanelTemplate = ({ data }) => {
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = useState(0);

  const handleFloatPanelVisibility = (data=null) => {
    dispatch(updateFloatPanelVisibility());
    dispatch(setFloatPanelComponent(<></>));
  };

  const handleHorizontalNavigation = (idTab) => {
    setTabActive(idTab);
  };

  return (
    <>
      <div className="floatPanelHeader">
        <div className="floatPanelHeaderLeft">
          <div onClick={() => handleFloatPanelVisibility(false)}>
            <IoMdClose />
          </div>
          <div>{data.title}</div>
          {data.isActivated && (
            <div className="isActive">
              Activo
              <Switch defaultChecked onClick={data.isActivated.callback} />
            </div>
          )}
        </div>
        <div className="floatPanelHeaderRight">
          {data.rightActions &&
            data.rightActions.map((button) => (
              <Button text={button.text} icon={button.icon} customClass={button.customClass} action={button.callback} />
            ))}
        </div>
      </div>
      {data.horizontalNavigation && data.horizontalNavigation.length > 0 && (
        <HorizontalNavigation options={data.horizontalNavigation} callback={handleHorizontalNavigation} tabActive={tabActive} />
      )}

      <div className="floatPanelBody">{data.content[tabActive].tabContent}</div>
    </>
  );
};
