//Components
import { InfoBlock } from "../../../../components/infoBlock";
import { LineChartComponent } from "../../../../components/charts/lineChart";
import { TbCoinEuro } from "react-icons/tb";
import { HiMiniArrowTrendingUp, HiMiniArrowTrendingDown } from "react-icons/hi2";
import { FiShoppingCart } from "react-icons/fi";
import { LiaShoppingBasketSolid } from "react-icons/lia";
import { BsPeople } from "react-icons/bs";
import { CiFilter } from "react-icons/ci";
import { PiExport } from "react-icons/pi";
import { FaSquare } from "react-icons/fa";
//Styles
import "./style.css";
import { Select } from "../../../../components/select";
import { Button } from "../../../../components/button";

export const Dashboard = () => {
  const infoBlocks = [
    { icon: <TbCoinEuro />, title: "Ventas", value: "5.901,09€", iconGraph: <HiMiniArrowTrendingUp />, valueGraph: "15,7%", graphVisible: true },
    {
      icon: <LiaShoppingBasketSolid />,
      title: "Pedidos",
      value: "221€",
      iconGraph: <HiMiniArrowTrendingUp />,
      valueGraph: "1,5%",
      graphVisible: true,
    },
    {
      icon: <FiShoppingCart />,
      title: "Valor del carrito",
      value: "450,09€",
      iconGraph: <HiMiniArrowTrendingDown color={"var(--color-red)"} />,
      valueGraph: "2,5%",
      graphVisible: true,
    },
    { icon: <BsPeople />, title: "Visitas", value: "284", iconGraph: <HiMiniArrowTrendingUp />, valueGraph: "32,6%", graphVisible: true },
    { icon: <CiFilter />, title: "Tasa de conversión", value: "4,5%", iconGraph: <HiMiniArrowTrendingUp />, valueGraph: "32,6%", graphVisible: true },
  ];

  return (
    <>
      <div className="topContainer">
        <div className="bodyTitle">Inicio</div>
        <div className="actionsDashboard">
          <Button text={"Exportar"} icon={<PiExport />} />
          <Select
            values={[
              { id: 0, name: "Todos los canales" },
              { id: 1, name: "Teléfono" },
              { id: 2, name: "Tienda física" },
              { id: 3, name: "WhatsApp" },
              { id: 4, name: "Correo electrónico" },
            ]}
          />
          <Select
            values={[
              { id: 0, name: "Últimos 30 días" },
              { id: 1, name: "Últimos 90 días" },
              { id: 2, name: "Últimos 120 días" },
              { id: 3, name: "Último año" },
            ]}
          />
        </div>
      </div>

      <div className="infoBlocksContainer">
        {infoBlocks.map((block) => (
          <InfoBlock
            icon={block.icon}
            title={block.title}
            value={block.value}
            iconGraph={block.iconGraph}
            valueGraph={block.valueGraph}
            graph={block.graphVisible}
          />
        ))}
      </div>

      <div className="chartContainer">
        <div className="adminPanel">
          <div className="adminPanelContent">
            <h3>Estadísticas</h3>
            <div className="dashboardStaticsResume">
              <div className="dashboardStaticsResumeBlock">
                <div className="dashboardStaticsResumeLabel">
                  <FaSquare color={"darkblue"} /> Ingresos totales
                </div>
                <div className="dashboardStaticsResumeValue">501,09 €</div>
              </div>
              <div className="dashboardStaticsResumeBlock">
                <div className="dashboardStaticsResumeLabel">
                  <FaSquare color={"lightblue"} /> Productos vendidos
                </div>
                <div className="dashboardStaticsResumeValue">1.951</div>
              </div>
            </div>
            <LineChartComponent yAxis={false} />
          </div>
        </div>
      </div>

      <div className="todoContainer">
        <div className="adminPanel">
          <div className="adminPanelContent">
            <h3>Tareas pendientes</h3>
            <div className="todoDashboard">
              {[1, 2].map((t, i) => (
                <div className="adminPanel" key={i}>
                  <div>{"Tarea " + t + " está pendiente de finalizar."}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/*<div className="mobile mobileSpaceBottom"></div>*/}
    </>
  );
};
