import "./style.css";

export const InfoBlock = ({ icon = <></>, customStyle = {}, title = "", value = <></>, graph = true, iconGraph = <></>, valueGraph = "" }) => {
  return (
    <div className="infoBlock" style={customStyle}>
      <div className="infoBlockTitle">
        {icon} <div>{title}</div>
      </div>
      <div className="infoBlockContent">
        <div className="infoValue">{value}</div>
        {graph && (
          <div className="infoBlockContentGraph">
            {iconGraph}
            <div>{valueGraph}</div>
          </div>
        )}
      </div>
    </div>
  );
};
