import React, { useState } from "react";
import { ConfigurationPanel } from "../..";
import { Select } from "../../../../../../components/select";

export const DevelopTime = () => {
  return (
    <ConfigurationPanel
      title="Tiempos de preparación"
      content={
        <div className="row">
          <div className="col-md-12">
            <Select
              values={[{ id: 0, name: "10 minutos" }]}
              callback={() => null}
              label="Tiempo estimado de preparación para tienda online"
              description=""
              classname={""}
            />
          </div>
        </div>
      }
    />
  );
};
