import { Input } from "../../../../components/inputs/type2";
import { HiOutlineSearch } from "react-icons/hi";
import { FloatPanelTemplate } from "../../../../components/floatPanel/floatPanelTemplate";
import { CustomBlock } from "../../../../components/customBlock";
import { StateLabel } from "../../../../components/stateLabel";

export const FloatComponent = ({ data }) => {
  const ClientDetails = () => {
    return (
      <div className="row gap-2 pt-2">
        <div className="col-12">
          <Input
            id={"nameInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Nombre/Razón Social"
            description="Escriba el nombre del cliente o la razón social"
          />
        </div>
        <div className="col-12">
          <Input
            id={"nifInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="NIF"
            description="Escriba el número de identificador fiscal"
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <Input
                id={"emailInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Email"
                description="Escriba el email"
              />
            </div>
            <div className="col-6">
              <Input
                id={"phoneInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Teléfono"
                description="Escriba el número de teléfono"
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-2">Dirección de envío</div>

        <div className="col-12">
          <Input
            id={"addressDeliveryInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Calle"
            description=""
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Input
                id={"cpInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Código postal"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                id={"cityInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Ciudad"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                id={"stateInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Pais"
                description=""
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-2">Dirección de envío secundaria</div>

        <div className="col-12">
          <Input
            id={"addressDeliveryInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Calle"
            description=""
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Input
                id={"cpInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Código postal"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                id={"cityInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Ciudad"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                id={"stateInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Pais"
                description=""
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-2">Dirección de facturación</div>

        <div className="col-12">
          <input type="checkbox" /> Igual que la dirección de envío
        </div>

        <div className="col-12">
          <Input
            id={"addressDeliveryInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Calle"
            description=""
          />
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Input
                id={"cpInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Código postal"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                id={"cityInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Ciudad"
                description=""
              />
            </div>
            <div className="col-4">
              <Input
                id={"stateInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Pais"
                description=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const OrderHistory = () => {
    const mockHistory = [
      { id: 0, name: "31524-F1", date: "05 de Julio de 2024", state: 1, total: "24.00 €" },
      { id: 0, name: "31524-F1", date: "05 de Julio de 2024", state: 2, total: "16.00 €" },
    ];

    const OrderRow = ({ data }) => {
      return (
        <div className="order-row">
          <div className="order-info">
            <div className="order-image"></div>
            <div className="order-detail">
              <div>
                <b>{data.name}</b>
              </div>
              <div>{data.date}</div>
            </div>
          </div>
          <div className="order-row-state">
            <StateLabel state={data.state} />
          </div>
          <div className="order-row-total">
            <div>Total</div>
            <div>
              <b>{data.total}</b>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="orderHistoryContainer">
        {mockHistory.map((order) => (
          <OrderRow data={order} />
        ))}
      </div>
    );
  };

  const content = {
    title: "Editar cliente",
    isActivated: { enabled: false, callback: () => console.log("action") },
    rightActions: [{ text: "Guardar", icon: <HiOutlineSearch />, customClass: "customButtonColor1", callback: () => console.log("action") }],
    horizontalNavigation: [],
    content: [
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <CustomBlock name={"Detalles"} content={<ClientDetails />} />
              </div>
              <div className="col-md-6 col-sm-12">
                <CustomBlock name={"Historial de pedidos"} content={<OrderHistory />} />
              </div>
            </div>
          </>
        ),
      },
    ],
  };
  return <FloatPanelTemplate data={content} />;
};
