import { useState, useEffect } from "react";
//Redux
import { useDispatch } from "react-redux";
import { updateDatatableCheckColumn, updateFloatPanelVisibility, setFloatPanelComponent } from "../../../../reducers/userConfigSlice";
//Components
import { dataClients } from "../../../../components/datatable/mock";
import Switch from "@mui/material/Switch";
import { Input } from "../../../../components/inputs/type1";
import { FloatRightPanel } from "../../../../components/floatRightPanel";
import { DragDropFiles } from "../../../../components/dragDropFiles";
import { Select } from "../../../../components/select";
import { TbSearch } from "react-icons/tb";
import { GoPlus } from "react-icons/go";
import { HiOutlineSearch } from "react-icons/hi";
import { LuArrowUpDown } from "react-icons/lu";
import { TbPackage } from "react-icons/tb";
import { PiCurrencyEur, PiEgg, PiFish, PiExport } from "react-icons/pi";
import { Button } from "../../../../components/button";
import {FloatComponent} from "./floatComponent";
//Functions
import { stateTags } from "../../../../functions";
//Styles
import "./style.css";
import { Datatable } from "../../../../components/datatable";

export const Clients = () => {
  const dispatch = useDispatch();
  


  const testCallback = () =>{
    //TODO request new data for table
    return null;
  }

  // =========== Datatable Configuration ============
  const sections = [{ text: null, callback: testCallback, actions:["search", "select"], actionsWithSelect:["clone", "delete", "cancel"] },];
  const customHeaders = {
    name: "Nombre",
    email: "Email",
    phone: "Teléfono",
    orders: "Pedidos",
  };
  const customData = {
    name: (data) => <div className="p-2">{data}</div>,
  };
  // ================================================

  const handleFloatPanelVisibility = (data=null) => {
    dispatch(updateFloatPanelVisibility());
    dispatch(setFloatPanelComponent(<FloatComponent data={data}/>));
  };

  useEffect(() => {
    dispatch(updateDatatableCheckColumn(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="topContainer">
        <div className="bodyTitle">Clientes</div>
        <div className="actionsDashboard">
          <Button text={"Exportar"} icon={<PiExport />}  />
        </div>
      </div>
      <div className="datatableContainer">
        {<Datatable data={dataClients} customHeaders={customHeaders} customData={customData} sections={sections} rowCallback={handleFloatPanelVisibility}/>}
      </div>
    </>
  );
};
