import { FiHome } from "react-icons/fi";
import { LuArrowRight } from "react-icons/lu";

export const PolicyList = ({ handlePolicy }) => {
  const mock = [
    { id: 0, icon: <FiHome />, title: "Política de devolución y reembolso" },
    { id: 1, icon: <FiHome />, title: "Política de privacidad" },
  ];

  const Policy = ({ data }) => {
    return (
      <div className="policyItem" onClick={() => handlePolicy(data)}>
        <div>{data.icon}</div>
        <div>{data.title}</div>
        <div>
          <LuArrowRight />
        </div>
      </div>
    );
  };

  return mock.map((policy) => <Policy data={policy} />);
};
