import { ConfigurationPanel } from "../..";
import { OwnerInformation } from "./ownerInformation";
import { SheduleShop } from "./sheduleShop";
import { CurrencyShop } from "./currencyShop";
import { ShopInformation } from "./shopInformation";

export const GeneralInformation = () => {
  return (
    <>
      <ConfigurationPanel title="Información de la tienda" content={<ShopInformation />} />
      <ConfigurationPanel title="Información del propietario" content={<OwnerInformation />} />
      <ConfigurationPanel title="Horario" content={<SheduleShop />} />
      <ConfigurationPanel title="Moneda" content={<CurrencyShop />} hasButtonSave={false} />
    </>
  );
};
