import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive, setUserLogOut } from "../../../../reducers/userConfigSlice";
//Components
import { TbPackage } from "react-icons/tb";
import { RiLogoutBoxRLine, RiShoppingBasketLine } from "react-icons/ri";
import { IoHelpCircleOutline } from "react-icons/io5";
import { CiDiscount1 } from "react-icons/ci";
import { AiOutlineUser } from "react-icons/ai";
import { FiHome } from "react-icons/fi";
import { BsShopWindow } from "react-icons/bs";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { BlockMenu } from "./blockMenu";
//Api
//import { logOut } from "../../../api/login";
//Styles
import "./style.css";

export const AdminMenu = ({ menuOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);

  const buttonRefs = useRef([]);

  const logOff = async () => {
    dispatch(setUserLogOut());
    navigate("/");
    //const response = await logOut();
  };

  const blockMenu1 = [
    { id: 101, name: "Inicio", icon: <FiHome />, component: "dashboard" },
    {
      id: 102,
      name: "Canal de venta",
      icon: <BsShopWindow />,
      component: "salesChannel",
    },
    { id: 103, name: "Pedidos", icon: <RiShoppingBasketLine />, component: "orders" },
    {
      id: 104,
      name: "Productos",
      icon: <TbPackage />,
      component: "products",
      childs: [
        { id: 1000, name: "Productos", component: "products", fatherComponent: "products" },
        { id: 1001, name: "Categorías", component: "categories", fatherComponent: "products" },
        { id: 1002, name: "Catálogos", component: "catalogs", fatherComponent: "products" },
        { id: 1003, name: "Atributos", component: "attributes", fatherComponent: "products" },
      ],
    },
  ];

  const blockMenu2 = [
    { id: 201, name: "Clientes", icon: <AiOutlineUser />, component: "clients" },
    { id: 202, name: "Descuentos", icon: <CiDiscount1 />, component: "discounts" },
  ];

  const blockMenu3 = [
    { id: 301, name: "Ayuda", icon: <IoHelpCircleOutline />, component: "" },
    { id: 302, name: "Configuración", icon: <HiOutlineCog6Tooth />, component: "configuration" },
    { id: 303, name: "Cerrar sesión", icon: <RiLogoutBoxRLine color={"var(--color-red)"} />, component: "logout" },
  ];

  return (
    <div className={menuOpen ? "adminSidebarMenuOpen adminSidebarMenu" : "adminSidebarMenu"}>
      <div className={menuOpen ? "menuTitleBlockOpen" : "menuTitleBlock"}>
        <div className={menuOpen ? "menuSeparator menuSeparatorOpen" : "menuSeparator"}></div>
        <div className="menuTitle">MENÚ</div>
      </div>
      <BlockMenu block={blockMenu1} menuOpen={menuOpen} />

      <div className={menuOpen ? "menuTitleBlockOpen" : "menuTitleBlock"} style={{ paddingTop: "10px" }}>
        <div className={menuOpen ? "menuSeparator menuSeparatorOpen" : "menuSeparator"}></div>
        <div className="menuTitle" style={{ marginTop: "15px" }}>
          MAS
        </div>
      </div>
      <BlockMenu block={blockMenu2} menuOpen={menuOpen} />

      <div className="menuBottom">
        <BlockMenu block={blockMenu3} menuOpen={menuOpen} />
      </div>
    </div>
  );
};
