import { Select } from "../../../../../../components/select";

export const CurrencyShop = () => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6 col-sm-12">
          <label>Seleccione una moneda para mostrar en la tienda</label>
        </div>
        <div className="col-md-6 col-sm-12" style={{ marginTop: "-25px" }}>
          <div className="row">
            <div className="col-md-6 offset-md-6 col-sm-12">
              <Select values={[{ id: 0, name: "EUR(€)" }]} callback={() => null} label="" description="" classname={""} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
