import { useState, useEffect } from "react";
//Redux
import { useDispatch } from "react-redux";
import { updateDatatableCheckColumn, updateFloatPanelVisibility, setFloatPanelComponent } from "../../../../reducers/userConfigSlice";
//Components
import { InfoBlock } from "../../../../components/infoBlock";
import { TbCoinEuro } from "react-icons/tb";
import { HiMiniArrowTrendingUp, HiMiniArrowTrendingDown } from "react-icons/hi2";
import { LiaShoppingBasketSolid } from "react-icons/lia";
import { HiOutlineSearch } from "react-icons/hi";
import { FiShoppingCart, FiRefreshCw, FiInbox } from "react-icons/fi";
import { CiFilter } from "react-icons/ci";
import { LuAlarmClock } from "react-icons/lu";
import { data } from "../../../../components/datatable/mock";
import { Button } from "../../../../components/button";
import { PiExport } from "react-icons/pi";
import {FloatComponent} from "./floatComponent";
//Functions
import { stateTags, parseDate } from "../../../../functions";
//Styles
import "./style.css";
import { Datatable } from "../../../../components/datatable";

export const Orders = () => {
  const dispatch = useDispatch();
  const testCallback = () =>{
    //TODO request new data for table
    return null;
  }

  // =========== Datatable Configuration ============
  const sections = [
    { text: "Todos", callback: testCallback, actions:["search", "filter", "order", "select"], actionsWithSelect:["delete", "cancel"] },
    { text: "Pendientes", callback: testCallback, actions:["search", "filter", "order", "select"], actionsWithSelect:["delete", "cancel"] },
    { text: "En preparación", callback: testCallback, actions:["search", "filter", "order", "select"], actionsWithSelect:["delete", "cancel"] },
    { text: "Preparado", callback: testCallback, actions:["search", "filter", "order", "select"], actionsWithSelect:["delete", "cancel"] },
    { text: "Carrito abandonado", callback: testCallback, actions:["search", "filter", "order", "select"], actionsWithSelect:["delete", "cancel"] },
  ];
  const customHeaders = {
    pedido: "Pedido",
    fecha: "Fecha",
    estadoPreparacion: "Estado de preparación",
    total: "Total",
    entrega: "Entrega",
    canal: "Canal",
    cliente: "Cliente",
    estadoPago: "Estado del pago",
  };
  const customData = {
    pedido: (data) => data,
    estadoPreparacion: (data) => stateTags(data),
    estadoPago: (data) => stateTags(data),
    total: (data) => <span>{data}€</span>,
    fecha: (data) => parseDate(data),
  };
  // ================================================

  const handleFloatPanelVisibility = (data=null) => {
    dispatch(updateFloatPanelVisibility());
    dispatch(setFloatPanelComponent(<FloatComponent data={data}/>));
  };

  const InfoBlocks = () => {
    return (
      <>
        <InfoBlock
          icon={<LiaShoppingBasketSolid />}
          title={"Pedidos totales"}
          value={"5.901,09€"}
          graph={true}
          iconGraph={<HiMiniArrowTrendingUp />}
          valueGraph={"15,7%"}
        />
        <InfoBlock
          icon={<TbCoinEuro />}
          title={"Valor promedio"}
          value={"221€"}
          graph={true}
          iconGraph={<HiMiniArrowTrendingUp />}
          valueGraph={"1,5%"}
        />
        <InfoBlock
          icon={<FiRefreshCw />}
          title={"Devoluciones"}
          value={"45,09€"}
          graph={true}
          iconGraph={<HiMiniArrowTrendingDown />}
          valueGraph={"2,5%"}
          theme="infoDanger"
        />
        <InfoBlock
          icon={<CiFilter />}
          title={"Tasa de conversión"}
          value={"284"}
          graph={true}
          iconGraph={<HiMiniArrowTrendingUp />}
          valueGraph={"32,6%"}
        />

        <InfoBlock
          icon={<LuAlarmClock />}
          title={"Tiempo de preparación"}
          value={"284"}
          graph={true}
          iconGraph={<HiMiniArrowTrendingUp />}
          valueGraph={"30,6%"}
        />

        <InfoBlock
          icon={<FiShoppingCart />}
          title={"Carritos abandonados"}
          value={"284"}
          graph={true}
          iconGraph={<HiMiniArrowTrendingUp />}
          valueGraph={"28,6%"}
        />
      </>
    );
  };
  return (
    <>
      <div className="topContainer">
        <div className="bodyTitle">Pedidos</div>
        <div className="actionsDashboard">
          <Button text={"Exportar"} icon={<PiExport />} />
        </div>
      </div>
      <div className="infoBlocksContainer">
        <InfoBlocks />
      </div>
      <div className="datatableContainer">
        {<Datatable data={data} customHeaders={customHeaders} customData={customData} sections={sections} rowCallback={handleFloatPanelVisibility}/>}
      </div>
    </>
  );
};
