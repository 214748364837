import "./style.css";

export const DatePicker = ({ label, description, callback }) => {
  const handleDayChange = (e) => {
    if (callback) {
      callback(e);
    }
  };

  return (
    <>
      <div className="inputLabel">{label}</div>
      <div className="inputDescription">{description}</div>
      <div className="datePicker">
        <input type="date" onChange={handleDayChange} />
      </div>
    </>
  );
};

export default DatePicker;
