import React, { useState } from "react";
import { ConfigurationPanel } from "../..";
import { BsPrinter } from "react-icons/bs";
import { Button } from "../../../../../../components/button";
import "./style.css";
import { Input } from "../../../../../../components/inputs/type2";
import { Select } from "../../../../../../components/select";

export const Printers = () => {
  const printerList = [{ id: 0, name: "Epson TM-P20", ip: "127.0.0.1", port: "3001", plataform: 1 }];

  const PrinterDesign = ({ data }) => {
    return (
      <div className="col-md-4 col-sm-12">
        <ConfigurationPanel
          title=""
          hasButtonSave={false}
          content={
            <div className="printer-container">
              <div className="printer-icon">
                <BsPrinter />
              </div>
              <div className="printer-row">
                <Input
                  id={"namePrinterInput"}
                  type={"text"}
                  placeholder={data.name}
                  icon={null}
                  classname={"w-100"}
                  iconPositionRight={true}
                  label="Nombre"
                  description=""
                />
              </div>
              <div className="printer-row">
                <Input
                  id={"ipPrinterInput"}
                  type={"text"}
                  placeholder={data.ip}
                  icon={null}
                  classname={"w-100"}
                  iconPositionRight={true}
                  label="Dirección IP"
                  description=""
                />
              </div>
              <div className="printer-row">
                <Input
                  id={"portPrinterInput"}
                  type={"text"}
                  placeholder={data.port}
                  icon={null}
                  classname={"w-100"}
                  iconPositionRight={true}
                  label="Puerto"
                  description=""
                />
              </div>
              <div className="printer-row">
                <Select values={[{ id: 0, name: "Punto de venta" }]} callback={() => null} label="Plataforma" description="" classname={""} />
              </div>
              <div className="printer-actions">
                <Button text={"Eliminar"} icon={null} customClass={""} action={() => null} />
                <Button text={"Actualizar"} icon={null} customClass={""} action={() => null} />
              </div>
            </div>
          }
        />
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-md-4 col-sm-12">
        <ConfigurationPanel
          title=""
          hasButtonSave={false}
          content={
            <>
              <div className="printer-icon">
                <BsPrinter />
              </div>
              <div className="printer-name">Agregar impresora</div>
              <div className="printer-actions">
                <Button text={"Agregar"} icon={null} customClass={""} action={() => null} />
              </div>
            </>
          }
        />
      </div>
      {printerList.map((printer) => (
        <PrinterDesign data={printer} />
      ))}
    </div>
  );
};
