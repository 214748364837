import { Select } from "../../../../components/select";
import Switch from "@mui/material/Switch";
import { HiOutlineSearch } from "react-icons/hi";
import { FloatPanelTemplate } from "../../../../components/floatPanel/floatPanelTemplate";
import { CustomBlock } from "../../../../components/customBlock";
import { Textarea } from "../../../../components/textarea/type1";
import { Input } from "../../../../components/inputs/type2";
import { DraggableList } from "../../../../components/draggableList";
import { NumberInputCustom } from "../../../../components/inputs/type3";

export const FloatComponent = ({ data }) => {
  const Details = () => {
    return (
      <div className="categoryDetails">
        <div>
          <div className="categoryInfo">
            <div className="categoryLogo"></div>
            <div className="categoryName">
              <Input
                id={"ownerNameInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Nombre"
                description="Nombre completo"
              />{" "}
            </div>
          </div>
        </div>
        <div>
          <Textarea id={"categoryDescriptionInput"} onChange={(e) => null} text={"Descripción"} label="Descripción de la categoría" />
        </div>
      </div>
    );
  };

  const Settings = () => {
    const settingsList = [
      {
        id: 0,
        name: "El cliente solo puede elegir un atributo",
        description: "El primer atributo será seleccionado por defecto",
        action1: null,
        action2: <Switch defaultChecked />,
      },
      {
        id: 1,
        name: "El cliente debe seleccionar siempre un atributo",
        description: "Un atributo es obligatorio",
        action1: null,
        action2: <Switch defaultChecked />,
      },
      {
        id: 1,
        name: "Máximo de atributos seleccionables",
        description: "Indica el máximo de atributos que se pueden seleccionar",
        action1: <NumberInputCustom />,
        action2: <Switch defaultChecked />,
      },
    ];

    return (
      <div className="settingAttributes">
        {settingsList.map((setting) => (
          <div className="settingRow">
            <div className="settingInfo">
              <div className="settingName">{setting.name}</div>
              <div className="settingDescription">{setting.description}</div>
            </div>
            <div className="settingAction1">{setting.action1}</div>
            <div className="settingAction2">{setting.action2}</div>
          </div>
        ))}
      </div>
    );
  };

  const content = {
    title: "Grupo de atributos",
    isActivated: { enabled: false, callback: () => console.log("action") },
    rightActions: [{ text: "Guardar", icon: <HiOutlineSearch />, customClass: "customButtonColor1", callback: () => console.log("action") }],
    horizontalNavigation: [],
    content: [
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <CustomBlock name={"Detalles"} content={<Details />} />
              </div>
              <div className="col-md-6 col-sm-12">
                <CustomBlock name={"Atributos"} content={<DraggableList />} description={"Modifique los atributos que necesite."} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <CustomBlock name={"Ajustes"} content={<Settings />} description={"Configure los atributos."} />
              </div>
            </div>
          </>
        ),
      },
    ],
  };
  return <FloatPanelTemplate data={content} />;
};
