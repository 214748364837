import "./style.css";

export const ColorPicker = ({ id, label, description }) => {
  return (
    <div className="inputType2Container">
      <div className="inputLabel">{label}</div>
      <div className="inputDescription">{description}</div>
      <div className="colorPicker">
        <input type="color" id={id} name="head" value="#000" />
      </div>
    </div>
  );
};
