import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { updateDatatableConfiguration, updateDatatableFilter, updateDatatableCheckColumn } from "../../reducers/userConfigSlice";
import { Pagination } from "./pagination";
import { Button } from "./../button";
import { HiOutlineSearch } from "react-icons/hi";
import "./style.css";

export const Datatable = ({ data, customHeaders, customData, sections, rowCallback = ()=>null }) => {
  const dispatch = useDispatch();
  const configuration = useSelector((state) => state.aplicationConfig.userConfig.datatable.configuration);
  const checkColumn = useSelector((state) => state.aplicationConfig.userConfig.datatable.checkColumn);
  const maxRows = useSelector((state) => state.aplicationConfig.userConfig.datatable.configuration.maxRows);

  const [selectedRows, setSelectedRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [activeSection, setActiveSection] = useState(0);

  const handleCheckColumn = () => {
    dispatch(updateDatatableCheckColumn());
  };

  const handleSectionData = (section, callback) => {
    setActiveSection(section);
    callback();
    //dispatch(updateDatatableFilter({ section: section, filter: [{ columns: [columns], value: filterBy }] }));
  };

  const handleSelectRow = (id, isChecked) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        // Añadir el id a la lista si está marcado
        return [...prevSelectedRows, id];
      } else {
        // Eliminar el id de la lista si está desmarcado
        return prevSelectedRows.filter((rowId) => rowId !== id);
      }
    });
  };

  useEffect(() => {
    const maxRowsInteger = parseInt(maxRows);
    let dataUpdated = data;
    //Filter
    const filtersActive = configuration.filterBy.filter.filter((f) => f.value !== "");
    if (filtersActive.length > 0) {
      filtersActive.map((filter) => {
        dataUpdated = dataUpdated.filter((f) => f[filter.columns] === filter.value);
      });
    }

    //Pagination
    dataUpdated = dataUpdated.filter((f, index) => index > configuration.indexStart - 1 && index < configuration.indexStart + maxRowsInteger);
    var tbody = document.getElementById("datatableTBody");
    tbody.scrollTop = 0;

    setRows(dataUpdated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  useEffect(() => {
    const maxRowsInteger = parseInt(maxRows);
    dispatch(updateDatatableConfiguration({ ...configuration, pages: Array.from(Array(parseInt(data.length / maxRowsInteger) + 1).keys()) }));
    setRows(data.filter((f, index) => index > configuration.indexStart && index < configuration.indexStart + maxRowsInteger));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = [
    { id:"search", text: "Buscar", icon: <HiOutlineSearch />, callback: () => console.log("Buscar action") },
    { id:"filter", text: "Filtrar", icon: <HiOutlineSearch />, callback: () => null },
    { id:"order", text: "Ordenar", icon: <HiOutlineSearch />, callback: () => null },
    { id:"select", text: "Seleccionar", icon: "", callback: handleCheckColumn },
  ];

  const actionsWithSelected = [
    { id:"clone", text: "Duplicar", icon: <HiOutlineSearch />, callback: () => console.log("Buscar action"), customClass: "customButtonColor1" },
    { id:"delete", text: "Eliminar", icon: <HiOutlineSearch />, callback: () => null, customClass: "customButtonColor1" },
    { id:"cancel", text: "Cancelar", icon: "", callback: handleCheckColumn, customClass: "customButtonColor2" },
  ];

  const availableActions = () =>{
    return actions.filter(f=>sections[activeSection].actions.includes(f.id));
  }

  const availableActionsWithSelected = () =>{
    return actionsWithSelected.filter(f=>sections[activeSection].actionsWithSelect.includes(f.id));
  }

  const DatatableComponent = () =>{
    return <div className="desktopDatatable">
    <div class="table-container">
      <table cellspacing="0">
        <thead>
          <tr>
            {checkColumn === true && (
              <th className="text-align-center" style={{ width: "50px" }}>
                <input type="checkbox" />
              </th>
            )}
            {Object.keys(data[0]).map((field) => {
              return <th className="text-align-center">{customHeaders[field] ? customHeaders[field] : field}</th>;
            })}
          </tr>
        </thead>
        <tbody id="datatableTBody">
          {rows.map((d,i) => {
            return (
              <tr className={selectedRows.includes(i) ? "rowSelected" : ""}>
                {checkColumn === true && (
                  <td className="text-align-center" style={{ width: "50px" }}>
                    <input type="checkbox" onChange={(e) => handleSelectRow(i, e.target.checked)} checked={selectedRows.includes(i)}/>
                  </td>
                )}
                {Object.keys(d).map((field) => {
                  return <td className="text-align-center" onClick={()=>rowCallback(d)}>{customData[field] ? customData[field](d[field]) : d[field]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
  }

  return (
    <div className="datatableBlock">
      <div className="adminPanel">
        <div className="headerDatatable">
          <div className="sectionsDatatable">
            {sections &&
              sections.map((s, i) => {
                return s.text !==null && (
                  <div className={i === activeSection ? "selectedHorizontalMenu" : ""} onClick={() => handleSectionData(i, s.callback)}>
                    {s.text}
                  </div>
                );
              })}
          </div>
          <div className="actionsDatatable">
            {checkColumn === true && availableActionsWithSelected().map((a, i) => {
                return <Button key={i} text={a.text} icon={a.icon} action={a.callback} customClass ={a.customClass ? a.customClass : ""}/>;
              })}
            {checkColumn === false && availableActions().map((a, i) => {
                return <Button key={i} text={a.text} icon={a.icon} action={a.callback} />;
              })}
          </div>
        </div>
        <DatatableComponent />
      </div>
      <Pagination />
    </div>
    
  );
};
