import { Select } from "../../../../components/select";
import { HiOutlineSearch } from "react-icons/hi";
import { FloatPanelTemplate } from "../../../../components/floatPanel/floatPanelTemplate";
import { CustomBlock } from "../../../../components/customBlock";
import { Textarea } from "../../../../components/textarea/type1";
import { Input } from "../../../../components/inputs/type2";

export const FloatComponent = ({ data }) => {
  const Details = () => {
    return (
      <div className="categoryDetails">
        <div>
          <div className="categoryInfo">
            <div className="categoryLogo"></div>
            <div className="categoryName">
              <Input
                id={"ownerNameInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Nombre"
                description="Nombre completo"
              />{" "}
            </div>
          </div>
        </div>
        <div>
          <Textarea id={"categoryDescriptionInput"} onChange={(e) => null} text={"Descripción"} label="Descripción de la categoría" />
        </div>
      </div>
    );
  };

  const content = {
    title: "Editar categoría",
    isActivated: { enabled: false, callback: () => console.log("action") },
    rightActions: [{ text: "Guardar", icon: <HiOutlineSearch />, customClass: "customButtonColor1", callback: () => console.log("action") }],
    horizontalNavigation: [],
    content: [
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <CustomBlock name={"Detalles"} content={<Details />} />
              </div>
              <div className="col-md-12 col-sm-12">
                <CustomBlock name={"Productos"} content={<></>} description={"Selecciona, activa o desactiva los productos que necesite."} />
              </div>
            </div>
          </>
        ),
      },
    ],
  };
  return <FloatPanelTemplate data={content} />;
};
