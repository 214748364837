import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./global.css";
import { idClient } from "./pages/login/alternativeSignin/google/oauth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={idClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>
);
