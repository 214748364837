import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdMenu } from "react-icons/md";
import "./style.css";

export const DraggableList = () => {
  const [items, setItems] = useState([
    { id: "1", name: "Elemento 1", options: "Royal, Fiji" },
    { id: "2", name: "Elemento 2", options: "Royal, Fiji" },
    { id: "3", name: "Elemento 3", options: "Royal, Fiji" },
    { id: "4", name: "Elemento 4", options: "Royal, Fiji" },
  ]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
  };

  const ItemDragable = ({ content, provided, snapshot }) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          userSelect: "none",
          padding: 8,
          margin: snapshot.isDragging ? "-75px 0 0px -100px" : "0 0 0px 0",
          backgroundColor: "transparent",
          color: "black",
          ...provided.draggableProps.style,
        }}
      >
        <div className="row">
          <div className="col-1">
            <MdMenu size={15} />
          </div>
          <div className="col-5 text-align-center">{content.name}</div>
          <div className="col-4 text-align-center">{content.options}</div>
          <div className="col-2 text-align-right">Editar</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="row tableDragableHeader">
          <div className="col-1"></div>
          <div className="col-5 text-align-center">Nombre</div>
          <div className="col-4 text-align-center">Opciones</div>
          <div className="col-2 text-align-right">Acciones</div>
        </div>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => <ItemDragable content={item} provided={provided} snapshot={snapshot} />}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
