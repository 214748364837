import { useState } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive } from "../../../../reducers/userConfigSlice";
//Components
import { GoHomeFill, GoInbox, GoPerson } from "react-icons/go";
import { PiPackageLight, PiMoney } from "react-icons/pi";
import { LiaBarsSolid } from "react-icons/lia";
import { ImCog } from "react-icons/im";
import { TbDiscount2 } from "react-icons/tb";
import { BsBarChart } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
//Styles
import "./style.css";

export const SidebarMobile = () => {
  const dispatch = useDispatch();
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);
  const [menuMobile, showMenuMobile] = useState(false);

  const optionsMenuMobile = [
    { id: 1, name: "Clientes", icon: <GoPerson />, component: "" },
    { id: 1, name: "Descuentos", icon: <TbDiscount2 />, component: "" },
    { id: 1, name: "Informes y estadísticas", icon: <BsBarChart />, component: "" },
    { id: 1, name: "Pagos", icon: <PiMoney />, component: "" },
    { id: 1, name: "Configuración", icon: <ImCog />, component: "" },
  ];

  const handleMenu = (component) => {
    dispatch(setComponentActive(component));
  };

  const handleMobileMenu = () => {
    console.log("handleMobileMenu");
    showMenuMobile(!menuMobile);
  };

  const BadgeMenuMobile = () => {
    return (
      <div className="badgeMenuMobile">
        {/*<div className="closeBadgeMenuMobile">
          <div onClick={() => handleMobileMenu()}></div>
    </div>*/}
        <div style={{ textAlign: "end", cursor: "pointer", padding: "0px 23px" }}>
          <IoCloseOutline onClick={() => handleMobileMenu()} />
        </div>
        {optionsMenuMobile.map((menu) => {
          return (
            <div>
              {menu.icon} <label>{menu.name}</label>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="bottom-toolbar">
      <div>
        <GoHomeFill className={componentActive === "dashboard" ? " optionMenuMobileActive " : ""} onClick={() => handleMenu("dashboard")} />
      </div>
      <div>
        <GoInbox className={componentActive === "orders" ? " optionMenuMobileActive " : ""} onClick={() => handleMenu("orders")} />
      </div>
      <div>
        <PiPackageLight
          className={componentActive === "products" ? " optionMenuMobileActive " : ""}
          size={"1.7rem"}
          onClick={() => handleMenu("products")}
        />
      </div>
      <div>
        <LiaBarsSolid className={menuMobile ? " optionMenuMobileActive " : ""} onClick={() => handleMobileMenu()} />
      </div>
      {menuMobile && (
        <>
          <div className="shadowBackground"></div>
          <BadgeMenuMobile />
        </>
      )}
    </div>
  );
};
