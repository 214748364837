import { Select } from "../../../../../../components/select";
import { ScheduleEditor } from "../../../../../../components/scheduleEditor";

export const SheduleShop = () => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6 col-sm-12">
          <Select
            values={[{ id: 0, name: "Personalizado" }]}
            callback={() => null}
            label="Tipo de horario"
            description="Horario comercial"
            classname={""}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Select
            values={[{ id: 0, name: "30 minutos" }]}
            callback={() => null}
            label="Tiempo del último pedido"
            description="Tiempo límite para recibir el último pedido"
            classname={""}
          />
        </div>
        <div className="col-md-12">
          <input type="checkbox" onChange={() => null} checked={false} className="mt-3" /> Horario de verano
        </div>
      </div>
      <ScheduleEditor />
    </>
  );
};
