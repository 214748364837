import React, { useState, useEffect } from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "./../../reducers/userConfigSlice";
//Icons
import { TbCircleCheck, TbAlertCircle } from "react-icons/tb";
//Style
import "./style.css";

export const Alert = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.aplicationConfig.userConfig.alert);
  const [isVisible, setIsVisible] = useState(false);

  const getColor = () => {
    switch (alert.type) {
      case "success":
        return { color: "var(--color-success)" };
      case "error":
        return { color: "var(--color-error)" };
      default:
        return { color: "var(--color-success)" };
    }
  };

  const getAlertIcon = () => {
    switch (alert.type) {
      case "success":
        return <TbCircleCheck style={{ color: getColor().color }} />;
      case "alert":
        return <TbAlertCircle />;

      default:
        break;
    }
  };

  const progressBar = () => {
    let progressBar = document.getElementById("progress-line");
    let width = 0;
    let interval = setInterval(function () {
      if (width >= 108) {
        clearInterval(interval);
      } else {
        width++;
        progressBar.style.width = width + "%";
      }
    }, 25);
  };

  useEffect(() => {
    if (alert.called) {
      // Mostrar la alerta después de un breve retraso
      const timeout = setTimeout(() => {
        setIsVisible(true);
        progressBar();
      }, 200);

      // Ocultar la alerta después de un tiempo
      const hideTimeout = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      // Ocultar la alerta después de un tiempo
      const hideTimeoutRedux = setTimeout(() => {
        dispatch(
          setAlert({
            called: false,
            message: "",
          })
        );
      }, 3500);

      // Limpiar los timeouts al desmontar el componente
      return () => {
        clearTimeout(timeout);
        clearTimeout(hideTimeout);
        clearTimeout(hideTimeoutRedux);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  return (
    <div className={`alert-banner ${isVisible ? "show" : "hide"}`}>
      <div className="alert-banner-body">
        <div className="message-icon">{getAlertIcon()}</div>
        <div>
          <div className="message-title">{alert.title}</div>
          <div className="message-description">{alert.message}</div>
        </div>
      </div>
      <div className="progress-line" id="progress-line" style={{ backgroundColor: getColor().color }}></div>
    </div>
  );
};
