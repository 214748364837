import { apiRest } from "./constants";

export const api = (data = null, path) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let headers = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(apiRest + path, headers)
    .then((response) => {
      // Verificar si la respuesta fue exitosa (código de estado 200-299)
      if (!response.ok) {
        throw new Error("La solicitud no fue exitosa");
      }
      if (!response.ok) return { ok: false, data: null };
      else return response.json();
    })
    .then((json) => {
      return { ok: true, data: json };
    })
    .catch((error) => {
      console.error(error);
    });
};

export const apiGet = (path) => {
  return fetch(apiRest + path)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
    });
};
