import React, { useState } from "react";
import { Select } from "../../../../components/select";
import { Textarea } from "../../../../components/textarea/type1";
import { Input } from "../../../../components/inputs/type2";
import { HiOutlineSearch } from "react-icons/hi";
import { FiHome } from "react-icons/fi";
import { FloatPanelTemplate } from "../../../../components/floatPanel/floatPanelTemplate";
import { CustomBlock } from "../../../../components/customBlock";
import { ScheduleEditor } from "../../../../components/scheduleEditor";

export const FloatComponent = ({ data }) => {
  const [scheduleSelected, setScheduleSelected] = useState(0);

  const Details = () => {
    return (
      <div className="categoryDetails">
        <div>
          <div className="categoryInfo">
            <div className="categoryLogo"></div>
            <div className="categoryName">
              <Input
                id={"ownerNameInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Nombre"
                description="Nombre completo"
              />{" "}
            </div>
            <div className="categoryName">
              <Input
                id={"idInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Identificador"
                description="Personalice el identificador"
              />{" "}
            </div>
          </div>
        </div>
        <div>
          <div className="categoryInfo">
            <div className="categoryName">
              <Input
                id={"addressInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Dirección (Opcional)"
                description="Escriba una dirección"
              />{" "}
            </div>
            <div className="categoryName">
              <Input
                id={"phoneInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Teléfono"
                description="Teléfono de contacto"
              />{" "}
            </div>
            <div className="categoryName">
              <Input
                id={"mailInput"}
                type={"text"}
                placeholder=""
                icon={null}
                classname={"w-100"}
                iconPositionRight={true}
                label="Correo"
                description="E-mail de contacto"
              />{" "}
            </div>
          </div>
        </div>
        <div>
          <Textarea id={"categoryDescriptionInput"} onChange={(e) => null} text={"Descripción"} label="Descripción de la categoría" />
        </div>
      </div>
    );
  };

  const Schedule = () => {
    return (
      <div className="categoryDetails">
        <div>
          <div className="categoryInfo">
            <div className="categoryName">
              <Select
                values={[
                  { id: 0, name: "Principal" },
                  { id: 1, name: "Personalizado" },
                ]}
                callback={(e) => setScheduleSelected(e)}
                label="Tipo de horario"
                description="Establezca el horario principal o personalizado"
                classname={""}
              />
            </div>
            <div className="categoryName">
              <Select
                values={[{ id: 0, name: "30 minutos" }]}
                callback={() => null}
                label="Tiempo del último pedido"
                description="Seleccione el tiempo límite para recibir el último pedido"
                classname={""}
              />
            </div>
          </div>
        </div>
        <div>
          {scheduleSelected === 0 && (
            <div>
              <div>
                <input type="checkbox" /> Horario de verano
              </div>
              <ScheduleEditor />
            </div>
          )}
        </div>
      </div>
    );
  };

  const PaymentDelivery = () => {
    const Card = ({ data }) => {
      return (
        <div className="cardPayment" onClick={() => null}>
          {data.icon}
          {data.name}
        </div>
      );
    };
    return (
      <div className="row">
        <div className="col-6 text-align-center paymentDeliveryContainer">
          <div>
            <b>Métodos de pago</b>
          </div>
          <div>2 métodos de pago seleccionados</div>
          <div className="paymentsMethodsBlock">
            <Card data={{ name: "Tarjeta", icon: <FiHome /> }} />
            <Card data={{ name: "Efectivo", icon: <FiHome /> }} />
          </div>
        </div>
        <div className="col-6 text-align-center paymentDeliveryContainer">
          <div>
            <b>Métodos de entrega y envío</b>
          </div>
          <div>2 métodos de entrega seleccionados</div>
          <div className="paymentsMethodsBlock">
            <Card data={{ name: "Domicilio", icon: <FiHome /> }} />
            <Card data={{ name: "Tienda", icon: <FiHome /> }} />
          </div>
        </div>
      </div>
    );
  };

  const content = {
    title: "Crear canal de venta",
    isActivated: { enabled: false, callback: () => console.log("action") },
    rightActions: [{ text: "Guardar", icon: <HiOutlineSearch />, customClass: "customButtonColor1", callback: () => console.log("action") }],
    horizontalNavigation: [
      { tab: 0, text: "Detalles principales", icon: null },
      { tab: 1, text: "Productos", icon: null },
    ],
    content: [
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <CustomBlock name={"Detalles"} content={<Details />} />
              </div>
              <div className="col-md-12 col-sm-12">
                <CustomBlock name={"Horario"} content={<Schedule />} />
              </div>
              <div className="col-md-12 col-sm-12">
                <CustomBlock name={""} content={<PaymentDelivery />} />
              </div>
            </div>
          </>
        ),
      },
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <CustomBlock
                  name={"Variables de precio"}
                  content={
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <Select
                          values={[{ id: 0, name: "Porcentaje" }]}
                          callback={() => null}
                          label="Variable"
                          description="Seleccione una variable para el precio de los productos"
                          classname={""}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <Input
                          id={"valueInput"}
                          type={"text"}
                          placeholder="0"
                          icon={null}
                          classname={""}
                          iconPositionRight={true}
                          label="Establezca un valor"
                          description="Establezca un valor variable"
                        />
                      </div>
                    </div>
                  }
                />
              </div>
              <div className="col-md-12 col-sm-12">
                <CustomBlock name={"Productos"} content={<></>} />
              </div>
            </div>
          </>
        ),
      },
    ],
  };
  return <FloatPanelTemplate data={content} />;
};
