import { Input } from "../../../../components/inputs/type2";
import { Select } from "../../../../components/select";
import { HiOutlineSearch } from "react-icons/hi";
import { FloatPanelTemplate } from "../../../../components/floatPanel/floatPanelTemplate";
import { CustomBlock } from "../../../../components/customBlock";
import DatePicker from "../../../../components/inputs/datePicker";
import TimePicker from "../../../../components/inputs/timePicker";
import { NumberInputCustom } from "../../../../components/inputs/type3";

export const FloatComponent = ({ data }) => {
  const Details = () => {
    return (
      <div className="row mt-2">
        <div className="col-12">
          <Input
            id={"discountCodeInput"}
            type={"text"}
            placeholder=""
            icon={null}
            classname={"w-100"}
            iconPositionRight={true}
            label="Código de descuento único"
            description="El cliente deberá indicar este código en el proceso de pago"
          />
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <Select
                values={[{ id: 0, name: "Porcentaje" }]}
                callback={() => null}
                label="Valor del descuento"
                description="Modo y el valor del descuento"
                classname={""}
              />
            </div>
            <div className="col-6">
              <Input
                id={"discountValueInput"}
                type={"text"}
                placeholder="10"
                icon={null}
                classname={"w-100 mt-3"}
                iconPositionRight={true}
                label=""
                description=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Schedule = () => {
    return (
      <div className="row mt-2">
        <div className="col-6">
          <DatePicker label={""} description={"Fecha de inicio"} callback={() => null} />
        </div>
        <div className="col-6">
          <TimePicker label={""} description={"Hora de inicio (CET)"} callback={() => null} />
        </div>
        <div className="col-12 mt-2">
          <input type="checkbox" /> Fijar fecha de finalización
        </div>
        <div className="col-6">
          <DatePicker label={""} description={"Fecha de fin"} callback={() => null} />
        </div>
        <div className="col-6">
          <TimePicker label={""} description={"Hora de fin (CET)"} callback={() => null} />
        </div>
      </div>
    );
  };

  const Settings = () => {
    return (
      <div className="row">
        <div className="col-6 mt-2">
          <div>
            <b>Requisitos de compra mínimos</b>
          </div>
          <div className="settingBuy">
            <div>
              <input type="radio" name="buymin" /> No hay requisitos
            </div>
            <div>
              <input type="radio" name="buymin" /> Valor mínimo de compra
              <Input
                id={"buyMinInput"}
                type={"text"}
                placeholder="10"
                icon={null}
                classname={"buyMinInput"}
                iconPositionRight={true}
                label=""
                description=""
              />
            </div>
          </div>
        </div>
        <div className="col-6 mt-2">
          <div>
            <b>Uso de descuentos máximos</b>
          </div>
          <div className="settingBuy-discounts">
            <div>
              <input type="checkbox" /> Límite de usos en total
              <NumberInputCustom />
            </div>
            <div>
              <input type="checkbox" /> Límite de usos por cliente
              <NumberInputCustom />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const content = {
    title: "Crear descuento",
    isActivated: { enabled: false, callback: () => console.log("action") },
    rightActions: [{ text: "Guardar", icon: <HiOutlineSearch />, customClass: "customButtonColor1", callback: () => console.log("action") }],
    horizontalNavigation: [],
    content: [
      {
        tabContent: (
          <>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <CustomBlock name={"Detalles"} content={<Details />} />
              </div>
              <div className="col-md-6 col-sm-12">
                <CustomBlock name={"Fechas de Inicio/Fin"} content={<Schedule />} />
              </div>
              <div className="col-md-12 col-sm-12">
                <CustomBlock name={"Ajustes"} content={<Settings />} />
              </div>
            </div>
          </>
        ),
      },
    ],
  };
  return <FloatPanelTemplate data={content} />;
};
