import { Input } from "../../../../../../components/inputs/type2";
import { TextEditor } from "../../../../../../components/textEditor";

export const PolicyDetail = () => {
  return (
    <div className="row mb-3">
      <div className="col-md-12 col-sm-12">
        <Input
          id={"polityTitleInput"}
          type={"text"}
          placeholder=""
          icon={null}
          classname={""}
          iconPositionRight={true}
          label="Título"
          description=""
        />
      </div>
      <div className="col-md-12 col-sm-12">
        <TextEditor />{" "}
      </div>
    </div>
  );
};
