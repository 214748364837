import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Sections
import Admin from "./pages/administration";
import Login from "./pages/login";
import RecoverPassword from "./pages/login/recoverPassword";
import { ProtectedRoute } from "./components/security/protectedRoute";
//Functions
import useLanguage from "./functions/useLanguage";

function App() {
  console.log("RENDER APP");
  useLanguage();
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/updatepassword/:token" element={<RecoverPassword />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute page="admin">
                <Admin />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
