import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//Redux
//Components
import { FormChangePassword } from "./alternativeSignin/forms/formChangePassword";
import { FormLogin } from "./alternativeSignin/forms/formLogin";
import { FormRegister } from "./alternativeSignin/forms/formRegister";
import { FormRecover } from "./alternativeSignin/forms/formRecover";
import { Google } from "./alternativeSignin/google";
import { Facebook } from "./alternativeSignin/facebook";
//Assets
import imgRightSide from "./assets/loginSide.jpg";
import Logo from "./../../assets/logo/logo.jpg";
//Api
//Styles
import "./style.css";
import "./responsive.css";

export default function Login() {
  const navigate = useNavigate();
  const [mode, setMode] = useState("login");
  const [title, setTitle] = useState("Iniciar sesión");

  const VerticalSeparator = () => {
    return <div style={{ borderRight: "1px rgba(0, 0, 0, 0.2) solid" }}></div>;
  };

  const handleMode = (newMode) => {
    if (newMode === "login") {
      setTitle("Iniciar sesión");
    }
    if (newMode === "register") {
      setTitle("Registro");
    }
    setMode(newMode);
  };

  const SocialArea = () => {
    return (
      <div>
        <div className="separatorLogin"></div>
        <div className="flex">
          <div className="w33"></div>
          <div className="iconSeparatorLogin">o</div>
          <div className="w33"></div>
        </div>
        <div className="socialLoginAuthButtons">
          <div>
            <Google />
          </div>
          <div>
            <Facebook />
          </div>
        </div>
      </div>
    );
  };

  const LoginArea = () => {
    return (
      <>
        <div className="loginContainer">
          <FormLogin callback={() => setMode("forgotPassword")} />
          {/*<VerticalSeparator />
          <div className="w33">
            <SocialArea />
            <div className="registerInfo">
              ¿Eres nuevo/a en Delibran? <span onClick={() => handleMode("register")}>Comenzar</span>
            </div>
          </div>*/}
        </div>
      </>
    );
  };

  const RegisterArea = () => {
    return (
      <>
        <div className="loginContainer">
          <FormRegister />
          <VerticalSeparator />
          <div className="w33">
            <SocialArea />
            <div className="registerInfo">
              <span onClick={() => handleMode("login")}>Ya tengo una cuenta</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ForgotPasswordArea = () => {
    return (
      <>
        <div className="loginContainer">
          <div>
            <div className="titleLogin text-align-center">¡Oh no! Perdiste la contraseña</div>
            <div className="subtitleLogin text-align-center">Ingresa tu email y recibirás un correo para cambiar tu contraseña</div>
            <FormRecover />
            <div className="registerInfo" style={{ marginTop: "15px" }}>
              <span onClick={() => setMode("login")}>Volver al inicio de sesión</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ChangePasswordArea = () => {
    const passwordChangedSuccessfully = (response) => {
      if (response) setMode("passwordChanged");
    };

    return (
      <>
        <div className="loginContainer">
          <div>
            <div className="titleLogin text-align-center">Recuerda guardarla muy bien</div>
            <div className="subtitleLogin text-align-center">Ingresa tu contraseña y verifícala</div>
            <FormChangePassword callback={passwordChangedSuccessfully} />
            <div className="registerInfo" style={{ marginTop: "15px" }}>
              <span onClick={() => handleMode("login")}>Volver al inicio de sesión</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const PasswordChangedArea = () => {
    return (
      <>
        <div className="loginContainer">
          <div>
            <div className="titleLogin text-align-center">Contraseña cambiada</div>
            <div className="subtitleLogin text-align-center" style={{ marginBottom: "15px" }}>
              Se cambió con éxito tu contraseña
            </div>
            <button className="button-1" onClick={() => setMode("login")}>
              Volver al inicio de sesión
            </button>
          </div>
        </div>
      </>
    );
  };

  const goToHome = () => {
    navigate("/");
  };

  const switchArea = () => {
    switch (mode) {
      case "login":
        return <LoginArea />;
      case "register":
        return <RegisterArea />;
      case "forgotPassword":
        return <ForgotPasswordArea />;
      case "changePassword":
        return <ChangePasswordArea />;
      case "passwordChanged":
        return <PasswordChangedArea />;
      default:
        return <LoginArea />;
    }
  };

  return (
    <div className="loginArea">
      <div className="topSide">
        <div className="logoContainer">
          <img src={Logo} alt="logo" className="logoLogin" onClick={goToHome} />
          <div className="titleLogin">{mode !== "login" && mode !== "register" ? "Contraseña olvidada" : title}</div>
        </div>
        <div className="formContainer">{switchArea()}</div>
      </div>
    </div>
  );
}
