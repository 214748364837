import React, { useState } from "react";
import { ConfigurationPanel } from "../..";
import { PolicyList } from "./policyList";
import { PolicyDetail } from "./policyDetail";

export const Policies = () => {
  const [policyDetails, showPolicyDetails] = useState(null);

  const handlePolicy = (policy) => {
    showPolicyDetails(policy);
  };
  return (
    <>
      {policyDetails === null ? (
        <ConfigurationPanel title="Políticas escritas" content={<PolicyList handlePolicy={handlePolicy} />} />
      ) : (
        <ConfigurationPanel title={policyDetails.title} content={<PolicyDetail policy={policyDetails} />} action={() => handlePolicy(null)} />
      )}
    </>
  );
};
