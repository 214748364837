export const language = {
  menu: {
    home: "Home",
    galery: "Galery",
    jobs: "Jobs",
    products: "Products",
    about: "About us",
    catalog: "Catalog",
    contact: "Contact",
    politicaPrivacidad: "Privacy Policy",
    avisoLegal: "Legal warning",
    company: "Company",
  },
};
