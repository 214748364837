import { ConfigurationPanel } from "../..";
import { Users } from "./users";

export const UsersAndPermissions = () => {
  return (
    <>
      <ConfigurationPanel title="Usuarios y permisos" content={<Users />} />
    </>
  );
};
