import React from "react";
import { useNavigate, useParams } from "react-router-dom";
//Redux
//Redux
import { useDispatch } from "react-redux";
import { setAlert } from "./../../reducers/userConfigSlice";
//Components
import { Input } from "../../components/inputs/type1";
import { AiOutlineEyeInvisible } from "react-icons/ai";
//Assets
import imgRightSide from "./assets/loginSide.jpg";
import Logo from "./../../assets/logo/logo-delibran.svg";
//Api
import { changePasswordByEmail } from "../../api/login";
//Styles
import "./style.css";
import "./responsive.css";

export default function RecoverPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { token } = useParams();

  const handlePassword = async () => {
    const password = document.getElementById("passwordInput").value;
    const data = {
      token: token,
      password: password,
    };

    const response = await changePasswordByEmail(data);
    if (response?.ok) {
      dispatch(
        setAlert({
          called: true,
          message: "Clave modificada, identifícate de nuevo.",
          type: "normal",
        })
      );
    } else {
    }
  };

  const NewPassword = () => {
    return (
      <>
        <div className="authLoginArea">
          <div className="titleLogin">Cambio de contraseña</div>
        </div>
        <div className="emailLoginArea">
          <p className="labelLogin">Nueva clave</p>
          <Input
            id={"passwordInput"}
            type={"password"}
            placeholder=""
            disabled={""}
            onKeyPress={() => null}
            icon={<AiOutlineEyeInvisible />}
            iconType={"tooglePasswordVisibility"}
            classname={""}
          />
          <button className="button-1" onClick={handlePassword}>
            Actualizar clave
          </button>
        </div>
      </>
    );
  };

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className="loginArea">
      <div className="topSide">
        <img src={Logo} alt="logo" className="logoLogin" onClick={goToHome} />
        <NewPassword />
      </div>
      <div className="bottomSide background-image" style={{ backgroundImage: `url(${imgRightSide})` }}></div>
    </div>
  );
}
