import { api, apiGet } from "./requests";

export const logIn = (data = null) => {
  let path = "login";
  return api(data, path);
};

export const logOut = (data = null) => {
  let path = "logout";
  return apiGet(path);
};

export const register = (data = null) => {
  let path = "login/register";
  return api(data, path);
};

export const recoverPassword = (data = null) => {
  let path = "login/recover-password";
  return api(data, path);
};

export const changePasswordByEmail = (data = null) => {
  let path = "login/change-password";
  return api(data, path);
};

export const googleLogIn = (data = null) => {
  let path = "login/google";
  return api(data, path);
};

export const analytics = (data = null) => {
  let path = "analytics";
  return apiGet(path);
};

/*export const saveAnalytics = (data = null) => {
  let path = "saveAnalytics";
  api(data, path);
};*/

/*export const login = (data = null) => {
  let path = "login";
  let headers = {
    method: "POST",
    body: JSON.stringify({ token: token, data: data }),
  };

  return fetch(url + path, headers)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const newAccount = (data = null) => {
  let path = "newUser";
  let headers = {
    method: "POST",
    body: JSON.stringify({ token: token, data: data }),
  };

  return fetch(url + path, headers)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const googleLogin = (data = null) => {
  let path = "googleLogin";
  let headers = {
    method: "POST",
    body: JSON.stringify({ token: token, data: data }),
  };

  return fetch(url + path, headers)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
    });
};*/
