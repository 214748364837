export const stateTags = (value) => {
  let border = "";
  let background = "";
  let color = "";

  switch (value) {
    case "Pagado":
    case "Entregado":
    case "Activado":
      border = "1px solid #1F9E8E33";
      background = "#1F9E8E0D";
      color = "#1F9E8E";
      break;

    case "Cancelado":
      border = "1px solid #CB4D3F33";
      background = "#FEE3E0";
      color = "#CB4D3FCC";
      break;

    case "No preparado":
    case "Pendiente":
      border = "1px solid #EB9E1A33";
      background = "#FFF0D9";
      color = "#EB9E1ACC";
      break;

    case "En preparación":
    case "Reembolsado":
      border = "1px solid #1F809E33";
      background = "#1F809E0D";
      color = "#2A91B1";
      break;

    default:
      break;
  }

  const tagStyle = {
    border: border,
    background: background,
    padding: "2px 10px",
    borderRadius: "6px",
    color: color,
    fontSize: "0.7rem",
    fontWeight: "700",
  };
  return <span style={tagStyle}>{value}</span>;
};

export const parseDate = (value) => {
  // Convertir la cadena de fecha en un objeto de fecha
  var fecha = new Date(value);

  // Obtener el día, mes y hora de la fecha
  var dia = fecha.getDate();
  var mes = fecha.toLocaleDateString("es-ES", { month: "long" }); // Obtener el nombre del mes en español
  var hora = fecha.getHours();
  var minutos = fecha.getMinutes();

  // Formatear la cadena de fecha en el formato deseado
  var fechaFormateada = `${dia} de ${mes} a las ${hora}:${minutos < 10 ? "0" : ""}${minutos}`;

  return fechaFormateada;
};

export const checkUserAgent = () => {
  let userAgent = "";

  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) != -1) {
    userAgent = "Opera";
  } else if (navigator.userAgent.indexOf("Edg") != -1) {
    userAgent = "Edge"; //Usado por Bing
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    userAgent = "Chrome"; //Usado por Opera
  } else if (navigator.userAgent.indexOf("CriOS") != -1) {
    userAgent = "ChromeIOS";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    userAgent = "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    userAgent = "Firefox";
  } else if (navigator.userAgent.indexOf("MSIE") != -1 || !!document.documentMode == true) {
    //IF IE > 10
    userAgent = "IE";
  } else {
    userAgent = "";
  }
  return userAgent;
};
