import { useEffect } from "react";
//Redux
import { useSelector } from "react-redux";
//Components
import { Dashboard } from "../sections/dashboard";
import { Orders } from "../sections/orders";
import { Products } from "../sections/products";
import { Catalogs } from "../sections/catalogs";
import { Attributes } from "../sections/attributes";
import { Categories } from "../sections/categories";
import { SalesChannel } from "../sections/salesChannel";
//Styles
import "./style.css";
import { Clients } from "../sections/clients";
import { Discounts } from "../sections/discounts";
import { Configuration } from "../sections/configuration";

export const Body = () => {
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);

  const handleComponent = () => {
    let component = <></>;
    switch (componentActive) {
      case "dashboard":
        component = <Dashboard />;
        break;
      case "salesChannel":
        component = <SalesChannel />;
        break;
      case "orders":
        component = <Orders />;
        break;
      case "products":
        component = <Products />;
        break;
      case "categories":
        component = <Categories />;
        break;
      case "attributes":
        component = <Attributes />;
        break;
      case "catalogs":
        component = <Catalogs />;
        break;
      case "clients":
        component = <Clients />;
        break;
      case "discounts":
        component = <Discounts />;
        break;
      case "configuration":
        component = <Configuration />;
        break;
      default:
        break;
    }
    return component;
  };

  useEffect(() => {
    document.getElementById("adminBody").scrollTo(0, 0);
  }, [componentActive]);

  return (
    <div className="adminBody" id="adminBody">
      {handleComponent()}
    </div>
  );
};
