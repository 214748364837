import React, { useState } from "react";
//Components
import { Input } from "../../../../components/inputs/type1";
import { AiOutlineEyeInvisible } from "react-icons/ai";

export const FormChangePassword = ({ callback }) => {
  const [error, setError] = useState(false);
  const handleChangePassword = () => {
    const password1 = document.getElementById("passwordInput1").value;
    const password2 = document.getElementById("passwordInput2").value;
    if (password1 === password2) {
      setError(false);
      callback(true);
    } else {
      setError(true);
    }
  };

  const FormPassword = () => {
    return (
      <div>
        <p className="labelLogin">Contraseña</p>
        <Input
          id={"passwordInput1"}
          type={"password"}
          placeholder=""
          disabled={""}
          onKeyPress={() => null}
          icon={<AiOutlineEyeInvisible />}
          iconType={"tooglePasswordVisibility"}
          classname={error ? "errorValueInput" : ""}
        />

        <p className="labelLogin">Verificar tu contraseña</p>
        <Input
          id={"passwordInput2"}
          type={"password"}
          placeholder=""
          disabled={""}
          onKeyPress={() => null}
          icon={<AiOutlineEyeInvisible />}
          iconType={"tooglePasswordVisibility"}
          classname={error ? "errorValueInput" : ""}
        />

        {error && (
          <p className="errorMessage errorValue text-align-center" style={{ paddingTop: "10px" }}>
            Las contraseñas no coinciden
          </p>
        )}

        <button className="button-1" onClick={handleChangePassword}>
          Cambiar contraseña
        </button>
      </div>
    );
  };

  return <FormPassword />;
};
