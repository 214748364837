import React, { useState } from "react";
import { Button } from "../../../../../../components/button";
import { Select } from "../../../../../../components/select";
import { Input } from "../../../../../../components/inputs/type2";
import { GoPlus } from "react-icons/go";

export const Users = () => {
  const [editionPanel, showEditionPanel] = useState(null);
  const mockUsers = [
    { id: 0, name: "Antonio Ruiz", permission: "ADMIN", mail: "antonioruiz@gmail.com" },
    { id: 1, name: "Patricio Santos", permission: "USUARIO", mail: "antonioruiz@gmail.com" },
  ];

  const handleEditionUser = (id) => {
    showEditionPanel(id);
  };

  const EditionUser = () => {
    return (
      <div className="editionUserBlock">
        <div className="row">
          <div className="col-md-12">
            <div className="configurationPanelContentTitle">Información general</div>
          </div>
          <div className="col-md-3 col-sm-12">
            <Input
              id={"nameInput2"}
              type={"text"}
              placeholder=""
              icon={null}
              classname={""}
              iconPositionRight={true}
              label="Nombre del usuario"
              description=""
            />
          </div>
          <div className="col-md-3 col-sm-12">
            <Input
              id={"mailInput2"}
              type={"text"}
              placeholder=""
              icon={null}
              classname={""}
              iconPositionRight={true}
              label="Correo electrónico"
              description=""
            />
          </div>
          <div className="col-md-3 col-sm-12">
            <Input
              id={"phoneInput2"}
              type={"text"}
              placeholder=""
              icon={null}
              classname={""}
              iconPositionRight={true}
              label="Teléfono de contacto"
              description=""
            />
          </div>
          <div className="col-md-3 col-sm-12">
            <Select
              values={[
                { id: 0, name: "Usuario" },
                { id: 1, name: "Administrador" },
              ]}
              callback={() => null}
              label="Rol"
              description=""
              classname={""}
            />
          </div>
          <div className="col-md-12 pt-4">
            <div className="configurationPanelContentTitle">Plataformas</div>
            <div className="plataformsUser">
              <div>
                <input type="checkbox" /> Gestión de pedidos
              </div>
              <div>
                <input type="checkbox" /> Punto de venta
              </div>
              <div>
                <input type="checkbox" /> Repartidor
              </div>
            </div>
          </div>
          <div className="col-md-12 pt-4">
            <div className="configurationPanelContentTitle">Código PIN</div>
          </div>
          <div className="col-md-2" style={{ alignItems: "center", display: "flex", gap: "10px" }}>
            Activo <input type="checkbox" />
          </div>
          <div className="col-md-2">
            <Button text={"Cambiar código PIN"} icon={null} customClass={"customButtonColor2"} action={() => null} />
          </div>
        </div>
      </div>
    );
  };

  const UserRow = ({ data }) => {
    return (
      <div className="row p-2">
        <div className="col-md-8">
          <div className="row mb-3">
            <div className="col-12">
              <b>{data.name}</b> <label className="badge">+ {data.permission}</label>
            </div>
            <div className="col-12">{data.mail}</div>
          </div>
        </div>
        <div className="col-md-4 flex-end">
          <Button text={"Eliminar"} icon={<GoPlus />} customClass={""} action={() => null} />
          <Button text={"Editar"} icon={<GoPlus />} customClass={"customButtonColor2"} action={() => handleEditionUser(data.id)} />
        </div>
        <div className="colr-md-12">{editionPanel === data.id && <EditionUser />}</div>
      </div>
    );
  };
  return (
    <>
      {mockUsers.map((user) => (
        <UserRow data={user} />
      ))}
    </>
  );
};
