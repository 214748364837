import React, { useState } from "react";
import { ConfigurationPanel } from "../..";
import { Delivery } from "./delivery";
import { Input } from "../../../../../../components/inputs/type2";

export const DeliveryMethods = () => {
  const [activePanels, setActivePanels] = useState([]);

  function toggleId(array, id) {
    const index = array.indexOf(id);
    if (index === -1) {
      array.push(id);
    } else {
      array.splice(index, 1);
    }
    return array;
  }

  const handleMethod = (id) => {
    setActivePanels((prevIds) => [...toggleId([...prevIds], id)]);
  };
  return (
    <>
      <ConfigurationPanel title="Métodos de entrega y envío" content={<Delivery handleMethod={handleMethod} activePanels={activePanels} />} />
      {activePanels.includes(0) && (
        <ConfigurationPanel
          title="Ajustes de domicilio"
          content={
            <div className="row mb-3">
              <div className="col-md-6 col-sm-12">
                <Input
                  id={"Name2Input"}
                  type={"text"}
                  placeholder="Recogida en tienda"
                  icon={null}
                  classname={""}
                  iconPositionRight={true}
                  label="Título"
                  description=""
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <Input
                  id={"priceInput"}
                  type={"text"}
                  placeholder="0"
                  icon={null}
                  classname={""}
                  iconPositionRight={true}
                  label="Coste del envío"
                  description=""
                />
              </div>
              <div className="col-6 mt-2">
                <div>
                  <b>Requisitos para el envío</b>
                </div>
                <div className="settingBuy">
                  <div>
                    <input type="radio" name="buymin" /> No hay requisitos
                  </div>
                  <div>
                    <input type="radio" name="buymin" /> Valor mínimo de compra
                    <Input
                      id={"buyMinInput"}
                      type={"text"}
                      placeholder="10"
                      icon={null}
                      classname={"buyMinInput"}
                      iconPositionRight={true}
                      label=""
                      description=""
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          action={() => handleMethod(false)}
        />
      )}
      {activePanels.includes(1) && (
        <ConfigurationPanel
          title="Ajustes de tienda"
          content={
            <div className="row mb-3">
              <div className="col-md-12 col-sm-12">
                <Input
                  id={"NameInput"}
                  type={"text"}
                  placeholder="Recogida en tienda"
                  icon={null}
                  classname={""}
                  iconPositionRight={true}
                  label="Título"
                  description=""
                />
              </div>
            </div>
          }
          action={() => handleMethod(false)}
        />
      )}
    </>
  );
};
