import React, { PureComponent } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import "./style.css";

const data = [
  {
    name: "Lunes",
    ingresos: 4000,
    ventas: 2400,
    amt: 2400,
  },
  {
    name: "Martes",
    ingresos: 3000,
    ventas: 1398,
    amt: 2210,
  },
  {
    name: "Miércoles",
    ingresos: 2000,
    ventas: 9800,
    amt: 2290,
  },
  {
    name: "Jueves",
    ingresos: 2780,
    ventas: 3908,
    amt: 2000,
  },
  {
    name: "Viernes",
    ingresos: 1890,
    ventas: 4800,
    amt: 2181,
  },
  {
    name: "Sábado",
    ingresos: 2390,
    ventas: 3800,
    amt: 2500,
  },
  {
    name: "Domingo",
    ingresos: 3490,
    ventas: 4300,
    amt: 2100,
  },
];

export const LineChartComponent = ({ yAxis = true }) => {
  class Component extends PureComponent {
    render() {
      return (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            {yAxis && <YAxis />}
            <Tooltip />

            <Line type="monotone" dataKey="ingresos" stroke="#0057FF" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="ventas" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      );
    }
  }
  return <Component />;
};
