import React, { useState } from "react";

//Components
import { AdminMenu } from "./sidebar/adminMenu";
import { AdminBar } from "./adminBar";
import { SidebarMobile } from "./sidebar/SidebarMobile";
import { AdminBarMobile } from "./sidebar/adminMenuMobile";
import { Body } from "./body";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import { FloatPanel } from "../../components/floatPanel";
import { Alert } from "../../components/alert";
//Styles
import "./style.css";

export default function Admin() {
  const [menuOpen, setMenuOpen] = useState(true);

  const AvatarArea = () => {
    return (
      <div className="adminAvatarArea">
        {menuOpen ? (
          <div className="row m-1">
            <div className="col-4">
              <div className="avatar"></div>
            </div>
            <div className="col-8">
              <div className="row">
                <div className="col-12 adminAvatarAreaRol">ADMIN</div>
                <div className="col-12 adminAvatarAreaName">Antonio Ruiz</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="avatar"></div>
        )}
        <div className="dropMenu" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <IoIosArrowDropleftCircle /> : <IoIosArrowDroprightCircle />}
        </div>
      </div>
    );
  };

  return (
    <div className="flex-start">
      <div className={menuOpen ? "adminLeftSide" : "adminLeftSide adminLeftSideClosed"}>
        <AvatarArea />
        <AdminMenu menuOpen={menuOpen} />
      </div>
      <div className={menuOpen ? "adminRightSide" : "adminRightSide adminRightSideClosed"}>
        <AdminBar />
        <AdminBarMobile />
        <SidebarMobile />
        <Body />
        <FloatPanel />
        <Alert />
      </div>
    </div>
  );
}
