import * as React from "react";
import ButtonMui from "@mui/material/Button";
import "./style.css";

export const Button = ({ type = "contained", text, icon = null, customClass = "customButtonColor1", action = () => null }) => {
  //type: text, contained, outlined

  return (
    <ButtonMui className={"customButton " + customClass} variant={type} onClick={action}>
      {icon && icon}
      <div className="customButtonText">{text}</div>
    </ButtonMui>
  );
};
