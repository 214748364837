import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive, setUserLogOut } from "../../../../reducers/userConfigSlice";
//Components
import { TbPackage } from "react-icons/tb";
import { RiLogoutBoxRLine, RiShoppingBasketLine } from "react-icons/ri";
import { IoHelpCircleOutline } from "react-icons/io5";
import { CiDiscount1 } from "react-icons/ci";
import { AiOutlineUser } from "react-icons/ai";
import { FiHome } from "react-icons/fi";
import { BsShopWindow } from "react-icons/bs";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { GoChevronDown, GoChevronUp } from "react-icons/go";

//Api
//import { logOut } from "../../../api/login";
//Styles
import "./style.css";

export const BlockMenu = ({ block, menuOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);
  const [selectedButton, setSelectedButton] = useState(null);
  const buttonRefs = useRef([]);

  const logOff = async () => {
    dispatch(setUserLogOut());
    navigate("/");
    //const response = await logOut();
  };

  const handleMenu = (option) => {
    if (option.id !== selectedButton) {
      if (option.component === "logout") logOff();
      else if (option.childs) {
        setSelectedButton(option.id);
      } else {
        setSelectedButton(null);
        dispatch(setComponentActive(option.component));
      }
    } else {
      setSelectedButton(null);
    }
  };

  const FloatingSubmenu = ({ items, callback }) => {
    return (
      <div className="floatingSubmenu">
        {items.map((i) => (
          <div
            onClick={() => callback(i)}
            className={i.component === componentActive ? "floatingSubmenuItem optionMenuActive" : "floatingSubmenuItem"}
          >
            {i.name}
          </div>
        ))}
      </div>
    );
  };

  const StaticSubmenu = ({ items, callback }) => {
    return (
      <div className="staticSubmenu">
        {items.map((i) => (
          <div
            onClick={() => callback(i)}
            className={i.component === componentActive ? "staticSubmenuItemActive staticSubmenuItem" : "staticSubmenuItem"}
          >
            <div className="subMenuSquare"></div>
            <div className="subMenuText">{i.name}</div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (!["floatingSubmenuItem", "floatingSubmenuItemSelected", "staticSubmenu", "subMenuSquare", "subMenuText"].includes(event.target.className)) {
        //setSelectedButton(null);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return block.map((option) => {
    const haveSubMenuOpen = menuOpen && selectedButton === option.id ? "subMenuIsOpen" : "";
    const haveChildSelected = option.childs && option.childs.length > 0 ? option.childs.filter((f) => f.component === componentActive) : [];
    const fatherComponent = option.childs && option.childs.length > 0 ? option.childs[0].fatherComponent : "";
    const activeStyle =
      (option.component === componentActive || (fatherComponent === option.component && haveChildSelected.length > 0)) && option.component !== ""
        ? " optionMenuActive "
        : "";
    return (
      <>
        <div
          onClick={() => handleMenu(option)}
          ref={(el) => (buttonRefs.current[option.id] = el)}
          className={menuOpen ? "optionMenuOpen " + haveSubMenuOpen + activeStyle : "optionMenu"}
        >
          <div className={menuOpen ? "" : "menuIconBig" + activeStyle}>{option.icon}</div>
          <div>{menuOpen && option.name}</div>
          {menuOpen && option.childs && option.childs.length > 0 && (
            <span className="subMenuArrowDown">{selectedButton === option.id ? <GoChevronUp /> : <GoChevronDown />}</span>
          )}
          {!menuOpen && selectedButton === option.id && <FloatingSubmenu items={option.childs} callback={handleMenu} />}
        </div>
        {menuOpen && selectedButton === option.id && <StaticSubmenu items={option.childs} callback={handleMenu} />}
      </>
    );
  });
};
