import { Select } from "./../select";

export const ScheduleEditor = () => {
  const CustomHours = ({ nameDay }) => {
    return (
      <div className="row">
        <div className="col-md-2 pt-4">{nameDay}</div>
        <div className="col-md-2">
          <Select values={[{ id: 0, name: "8:00" }]} callback={() => null} label="" description="" classname={""} />
        </div>
        <div className="col-md-2">
          <Select values={[{ id: 0, name: "14:00" }]} callback={() => null} label="" description="" classname={""} />
        </div>
        <div className="col-md-2">
          <Select values={[{ id: 0, name: "17:00" }]} callback={() => null} label="" description="" classname={""} />
        </div>
        <div className="col-md-2">
          <Select values={[{ id: 0, name: "00:00" }]} callback={() => null} label="" description="" classname={""} />
        </div>
        <div className="col-md-2 pt-3">
          <input type="checkbox" onChange={() => null} checked={false} className="mt-3" /> Cerrado
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-4 text-center">
          <small>Desde</small>
        </div>
        <div className="col-md-4 text-center">
          <small>Hasta</small>
        </div>
        <div className="col-md-2"></div>
      </div>
      {["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"].map((day) => (
        <CustomHours nameDay={day} />
      ))}
    </>
  );
};
