import React, { useState } from "react";
import { ConfigurationPanel } from "../..";
import { Select } from "../../../../../../components/select";

export const AdvancedConfiguration = () => {
  return (
    <ConfigurationPanel
      title="Configuración avanzada"
      content={
        <div className="row">
          <div className="col-md-12">
            <Select
              values={[{ id: 0, name: "1 hora" }]}
              callback={() => null}
              label="Tiempo que determina si un carrito está abandonado"
              description=""
              classname={""}
            />
          </div>
        </div>
      }
    />
  );
};
