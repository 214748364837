import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import SelectMui, { SelectChangeEvent } from "@mui/material/Select";
import { TbChevronDown, TbChevronUp } from "react-icons/tb";
import "./style.css";

export const Select = ({ icon = null, value, values, isMenu = false, className = "", callback = null, label=null, description=null }) => {
  const [selected, setSelected] = useState(value ? value : values[0].id);
  const [visibility, changeVisibility] = useState(false);
  const [childWidth, setChildWidth] = useState(150);
  const idSelect = Math.floor(Math.random() * 1000000 + 1);

  const handleOption = (option) => {
    if (!isMenu) {
      setSelected(option.id);
      changeVisibility(!visibility);
      if (callback) callback(option.name);
    } else {
      changeVisibility(!visibility);
      option.function();
    }
  };

  const getWidth = () => {
    const width = document.getElementById("select-" + idSelect);
    return width.offsetWidth;
  };

  useEffect(() => {
    if (visibility) setChildWidth(getWidth() - 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility]);

  return (
    <div className="selectContainer">
    {label!==null && <div className="inputLabel">{label}</div>}
    {label!==null && <div className="inputDescription">{description}</div>}
      <SelectMui
        className={"customSelect "+className}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={0}
        label="Age"
        onChange={() => null}
        displayEmpty
      >
        {values.map((v, i) => (
          <MenuItem key={i} value={v.id}>
            {v.name}
          </MenuItem>
        ))}
      </SelectMui>
    </div>
  );
};
