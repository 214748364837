export const language = {
  menu: {
    home: "Inicio",
    galery: "Galería",
    jobs: "Trabajos",
    products: "Productos",
    about: "Conócenos",
    catalog: "Catálogo",
    contact: "Contacto",
    politicaPrivacidad: "Política de privacidad",
    avisoLegal: "Aviso legal",
    company: "Empresa",
  },
};
