//Redux
import { useSelector } from "react-redux";
import "./style.css";

export const FloatPanel = () => {
  const floatPanel = useSelector((state) => state.aplicationConfig.userConfig.floatPanel);

  const Panel = () => {
    return (
      <>
        <div className={`floatPanel ${floatPanel.visible ? "scale-up-ver-top" : ""}`}>{floatPanel.component}</div>
        <div className="shadow"></div>
      </>
    );
  };

  return floatPanel.visible && <Panel />;
};
